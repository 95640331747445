$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;


.KPITimeFrame{
  // width: 100%;
  // height: 100%;
}

.KPITimeFrame 
.kpiTimeFrameSettingsPopupClasspopUpOuterContaier
.kpiTimeFrameSettingsPopupClassPopUpInnerContaier{
  height: 100%;
  width: 100%;
}
.KPITimeFrame 
.kpiTimeFrameSettingsPopupClasspopUpOuterContaier
.kpiTimeFrameSettingsPopupClassPopUpInnerContaier
.kpiTimeFrameSettingsPopupClassChildernContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.KPITimeFrame .kpiTimeFrameSettingsPopupClass .popupTitleDiv {
  height: 3rem;
  .popupTitle{
    font-size: 1.5rem;
    text-align: start;
    font-weight: 600;
  }
}
.KPITimeFrame .kpiTimeFrameSettingsPopupClass .kpiTimeFrameSettingsContainer{
  height: calc(100% - 3.5rem);
  flex-direction: column;
  display: flex;
  gap: .5rem;
}

// Buttons
.KPITimeFrame .primaryButton{
  border: none;
  outline: none;
  background: $blue-color;
  color: $white-color;
  font-weight: 600;
  font-size: .9rem;
  border-radius: 6px;
  height: 3rem;
  padding: 0.5rem 1rem;

  transition: all 200ms ease-in-out;
}
.KPITimeFrame .secondaryButtonColor{
  background: $blue-light-2-color;
  color: $blue-color;
}
.KPITimeFrame .secondaryButtonColor:hover{
  color: $white-color;
}
.KPITimeFrame .primaryButton.secondaryButton:disabled,
.KPITimeFrame .primaryButton:disabled{
  background: $secondary-color;
}
.KPITimeFrame .primaryButton:disabled:hover{
  background: $secondary-color;
}

.KPITimeFrame .primaryButton:hover{
  background: #0091e4;

}
.KPITimeFrame .primaryButton:active{
  background: #007cc4;

}

// removeButton
.KPITimeFrame .primaryButton.removeButton , .KPITimeFrame .primaryButtonSmall.removeButton{
  background: $red-1-color;
  background: $secondary-color;
}
.KPITimeFrame .primaryButton.removeButton:hover , .KPITimeFrame .primaryButtonSmall.removeButton:hover{
  background: $red-2-color;
}
.KPITimeFrame .primaryButton.removeButton:active, .KPITimeFrame .primaryButtonSmall.removeButton:active{
  background: $red-3-color;
}


.KPITimeFrame .kpiTimeFrameSettingsContainer .headerOptions{
 display: flex;
 justify-content: space-between;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .headerOptions .timeframeRangeOptionsContainer{
 display: flex;
 background-color: $gray-light-3-color;
 border-radius: 6px;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .headerOptions .timeframeRangeOptionsContainer .timeframeRangeOption{
  padding:  1rem 2rem;
  font-weight: 500;
  cursor: pointer;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .headerOptions .timeframeRangeOptionsContainer .timeframeRangeOption.activeTimeframeRangeOption{
  background-color: $blue-color;
  border-radius: 6px;
  color: $white-color;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .headerOptions .timeFramePeriodSelector {
  display: flex;
  gap: 1rem;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .headerOptions .timeFramePeriodSelector .titleDiv{
  display: flex;
  align-items: center;
  font-weight: 500;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .headerOptions .timeFramePeriodSelector .timeFramePeriodSelectorOption{
  width: 6rem;
}
$borderColor:$blue-light-1-color;
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorContainer{
  height: calc(100% - 3rem - 1rem);
  position: relative;
  border: 1px solid $borderColor;
  overflow: auto;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTableHeader{
  background-color:$gray-light-3-color; 
  border-bottom: 1px solid $borderColor;
  display: flex;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTableHeader .kpiTargetPointsKpiTypeTitleOuter{
  font-weight: 600;
  position: relative;
}

.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTableHeader .kpiTargetPointsKpiTypeTitleOuter .kpiTargetPointsKpiTypeTitleInner{
  height: calc(8rem + 3px);
  position: absolute;
  top: -2px;
  left: 0px;
  padding: 1rem;
  min-width: calc(12rem + 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 1;
  background-color:$gray-light-3-color; 
  border: 1px solid $borderColor;
  border-left: 0;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTableHeader .kpiTargetPointsTitleDiv{
  font-weight: 600;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .cellDiv{
  padding: 1rem;
  min-width: 12rem;
  height: 4rem;
  display: flex;
  align-items: center;
}

  .KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable{
  width: 100%;

  height: calc(100% - 4rem - 2px);
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .cellDiv{
  padding: 1rem;
  min-width: 12rem;
  height: 4rem;
  display: flex;
  align-items: center;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeColumn{
  display: flex;
  flex-direction: column;
  border-right: 1px solid $borderColor;
 
}

.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeColumn .cellDiv{
  justify-content: center;
  border-bottom: 1px solid $borderColor;
  background-color:$gray-light-3-color; 
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeColumn .kpiPeriodRangekpiPeriodRangeTitle{
  height: 8rem;
  font-weight: 600;
  position: sticky;
  top: 1px;
  box-shadow: 0 -1px 0 0 $borderColor;
}

.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnOuterContainer{
  width: 100%;
}

.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnOuterContainer .kpiPeriodRangeEditColumnOuterContainerTitle{
  width: 100%;
  border-bottom: 1px solid $borderColor;
  justify-content: center;
  font-weight: 600;
  background-color:$gray-light-3-color; 
  position: sticky;
  top: 1px;
  box-shadow: 0 -1px 0 0 $borderColor;
}

.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer{
  display: flex;
  overflow: auto;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer .kpiPeriodTypeCellContainer{
  border-right: 1px solid $borderColor;
 
  background-color:$gray-light-3-color; 
}
  .KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer .kpiPeriodRangeEditColumn{
  border-right: 1px solid $borderColor;
  width: 100%;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer .kpiPeriodRangeEditColumn.kpiPeriodRangeAddColumn{
  width: auto;
  border-right: 1px solid $borderColor;
  .cellDiv{
    min-width: 4rem;
    background-color:$gray-light-3-color;
  }
  .kpiPeriodRangeTitleColumn{
    display: flex;
    justify-content: center;
    font-weight: 600;
    position: sticky;
    top: 0;
    cursor: pointer;
    transition: 100ms ease-in-out;
    &:hover{
      background-color: $blue-color;
      color: $white-color;
    }
    .columnAddIcon{
      font-size: 2rem;
    }
    .editCellInputTextDiv{
      width: 100%;
      min-width: 10rem;
    }
    
  }
  .kpiPeriodRangeTitleColumn.isInputState{
    transition: 0ms ease-in-out;
    background-color:$gray-light-3-color;
  }
  }
.KPITimeFrame .kpiTimeFrameSettings Container .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer  .kpiPeriodRangeTitleColumn{
  display: flex;
  font-weight: 600;
  transition: 200ms ease-in-out;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer  .kpiPeriodRangeTitleColumn:hover{
  box-shadow: inset  0 0 0 1px $blue-color;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer  .kpiPeriodRangeTitleColumn .editCellInputTextDiv{
  width: 100%;
  input{
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
  }
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer .cellDiv{
  border-bottom: 1px solid $borderColor;
}

.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer .kpiPeriodRangeEditCellDiv{
  justify-content: center;
  transition: 200ms ease-in-out;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer .kpiPeriodRangeEditCellDiv:hover{
  box-shadow: inset  0 0 0 1px $blue-color;
}
.KPITimeFrame .kpiTimeFrameSettingsContainer .kpiTargetPointsEditorTable .kpiPeriodRangeEditColumnInnerContainer .kpiPeriodRangeEditCellDiv .editCellInputTextDiv{
  width: 100%;
  input{
    width: 100%;
    border: none;
    outline: none;
  }
}