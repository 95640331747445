.CustomDropdown.layoutOptionDropdown{
  display: block;
}
.CustomDropdown.layoutOptionDropdown div{
  display: block;
}
.CustomDropdown.layoutOptionDropdown  .mainTitle{
  padding: 0; background: transparent;
}
.CustomDropdown.layoutOptionDropdown  .dropDownArray{
 right: 0;
 width: 10rem;
}