$primary-color: #3f4254;
$secondary-color: #7e8299;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #d92232;
$red-2-color: #c71624;
$red-3-color: #a7111e;

.kpiTimeframeGridContainer {
  display: grid;
  overflow: auto;
  align-content: baseline;
  width: 100%;
  height: calc(100% - 4rem);
  height: calc(100% - 4rem - 2px);
  position: absolute;
  // gap: 1px;
}

$borderColor: $blue-light-1-color;

.kpiTimeframeGridContainer .gridCell {
  padding: 1rem;
  min-width: 12rem;
  width: 100%;
  // width: max-content;
  // width: min-content;
  // width: clamp(min-content, 100%, auto);
  height: 4rem;
  display: flex;
  align-items: center;
  // border: 1px solid $borderColor;
  .editCellInputTextDiv{
    width: 100%;
  }
  input{
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    // box-shadow: inset  0 0 0 1px black;
    background-color: transparent;
  }
}
.kpiTimeframeGridContainer .gridCell.upperColumnGridCell {
  position: sticky;
  top: 0;
  background-color: $gray-light-3-color;
  border-bottom: 1px solid $borderColor;
  border-right: 1px solid $borderColor;

}
.kpiTimeframeGridContainer .gridCell.upperColumnGridCell:nth-last-child(1) {
  border-right: none;
}
.kpiTimeframeGridContainer .gridCell.upperColumnGridCell .upperColumnGridCell{
 display: flex;
}
.kpiTimeframeGridContainer .gridCell.upperColumnGridCell .titleGridCellLableDiv{
  width: calc(100% - 1rem);
  overflow: hidden;
  text-overflow: ellipsis;
}
.kpiTimeframeGridContainer .gridCell.upperColumnGridCell .titleGridCellOptionDiv{
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kpiTimeframeGridContainer .gridCell.upperColumnGridCell .titleGridCellOptionDiv {
  .mainTitle{
  padding: 0;
  margin: 0;
  width: auto;
}
.dropDownArray{
  width: 10rem;
  right: 10px;
  top: 40px;
}
}
  .kpiTimeframeGridContainer .gridCell.sideColumnGridCell {
  position: sticky;
  width: 12rem;
  left: 0;
  background-color: $gray-light-3-color;
  border-bottom: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
}
.kpiTimeframeGridContainer .gridCell.valueColumnGridCell {
  // width: 100%;
  border-bottom: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
}
.kpiTimeframeGridContainer .gridCell.sideColumnGridCell.firstBlankGridCell {
  border-bottom: none;
  border-right: 1px solid $borderColor;
}
  .kpiTimeframeGridContainer .gridCell.addColumnGridCell {
  position: sticky;
  width: calc(4rem + 1px);

  background-color: $gray-light-3-color;
  min-width: 4rem;
  left: calc(12rem - 0px);
  z-index: 1;
  border-bottom: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  // border: 1px solid $borderColor;
}
.kpiTimeframeGridContainer .gridCell.upperColumnGridCell.addColumnGridCell {
  z-index: 2;

}

.kpiTimeframeGridContainer {
  .gridCell{
    min-width: 4rem;
    // width: 4rem;
    background-color:$gray-light-3-color;
  }
  .kpiPeriodRangeTitleColumn{
    display: flex;
    justify-content: center;
    font-weight: 600;
    position: sticky;
    top: 0;
    cursor: pointer;
    transition: 100ms ease-in-out;
    &:hover{
      background-color: $blue-color;
      color: $white-color;
    }
    .columnAddIcon{
      font-size: 2rem;
    }
    .editCellInputTextDiv{
      width: 100%;
      min-width: 10rem;
    }
    
  }
  .kpiPeriodRangeTitleColumn.isInputState{
    transition: 0ms ease-in-out;
    background-color:$gray-light-3-color;
    .editCellInputTextDiv{
      width: 100%;
      input{
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent;
      } 
    }
  }
  }