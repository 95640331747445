$yellow-1-color:#856404;
$yellow-2-color:#ffeeba;
$yellow-3-color:#fff3cd;
$green-1-color:#155724;
$green-2-color:#c3e6cb;
$green-3-color:#d4edda;
$red-1-color:#721c24;
$red-2-color:#f5c6cb;
$red-3-color:#f8d7da;

:root {
  // --toastify-icon-color-warning:#fff3cd;
  // --toastify-icon-color-success:#d4edda;
  // --toastify-icon-color-error:#f8d7da;
}

// warning
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: $yellow-3-color;
  color: $yellow-1-color;
  .Toastify__close-button {
    color: $yellow-1-color;
  }
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning{
  background-color: $yellow-1-color;
}

// success
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: $green-3-color;
  color: $green-1-color;
  .Toastify__close-button {
  color: $green-1-color;
}
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success{
  background-color: $green-1-color;

}
.Toastify__progress-bar--success {
  background-color: $green-3-color;
}
.Toastify__toast-icon--success{
  color: $green-3-color;
}

// error
.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: $red-3-color;
  color: $red-1-color;
  .Toastify__close-button {
    color: $red-1-color;
  }
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error{
  background-color: $red-1-color;
}

