.supistaCard {
  min-height: 188px;
}
.supistaCard .supistaCardTitle{
  display: block;
  width: calc( 100% - 2.5rem);
}
.supistaCard .importTableButton{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -1.5rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}