$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;

.CustomDropdown{
    position: relative;
    width: 100%;
}
.CustomDropdown .mainTitle{
    background-color: $gray-light-3-color;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    width: 100%;
    padding: 0.5rem 1rem;
    outline: none;
    color: $primary-color;
    border: none;
    margin: .5rem 0;
    cursor: pointer;
    user-select: none;
}
.CustomDropdown .mainTitle input{
    width: calc( 100%  - .75rem);
    // width: 100%;
    // padding: 0.5rem 0.75rem;
    margin-right: .75rem;
    background: $gray-light-3-color;
    outline: none;
    border: none;
    border-radius: 6px;

}
.CustomDropdown .mainTitle img{
    height: 30%;
    transition: 200ms ease-in-out;
    margin-left: auto;
}
.CustomDropdown .mainTitle img.open{
    transform: rotate(180deg);
}

.CustomDropdown .fullDiv {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    // background-color: #00A3FF;
}
.CustomDropdown .dropDownArray {
    z-index: 5;
    max-height: 0px;
    position: absolute;
    width: 95%;
    margin: 0 2.5%;
    background-color: $gray-light-2-color;
    box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    border-radius: 6px;
    flex-direction: column;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.CustomDropdown .dropDownArray.open {
    min-height: 2.5rem;
    max-height: 15em;
    overflow: auto;
    transition: max-height 300ms ease-in-out;
}

.CustomDropdown .dropDownArray .dropDownItems{
    padding: .25rem 1rem;
    cursor: pointer;
    background-color: $white-color;
    transition: 300ms ease-in-out;
    // overflow: hidden;
    // text-overflow: ellipsis;
    overflow-wrap: break-word;

}
.CustomDropdown .dropDownArray .dropDownItems.selected{
    background-color: $gray-light-1-color;

}
.CustomDropdown .dropDownArray .dropDownItems:hover{
    background-color: $gray-light-2-color;
}
.CustomDropdown .dropDownArray .dropDownItems:nth-child(1){
    padding-top: .5rem;
}
.CustomDropdown .dropDownArray .dropDownItems:nth-last-child(1){
    padding-bottom: .5rem;
}

// Nested Div
.CustomDropdown .dropDownArray.nestedDropDownWrapper{
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    gap: .25rem;
}


.CustomDropdown .dropDownArray.nestedDropDownWrapper .nestedDropDownGroupWrapper {
    padding: .25rem;
    border-radius: 6px;
}
.CustomDropdown .dropDownArray.nestedDropDownWrapper .nestedDropDownGroupWrapper .nestedDropDownGroupTitle{
    font-weight: 500;
}
.CustomDropdown .dropDownArray.nestedDropDownWrapper .nestedItemsContainer{
    padding: .25rem;
    display: flex;
    flex-direction: column;
}
.CustomDropdown .dropDownArray.nestedDropDownWrapper .nestedItemsContainer .nestedItems{
    padding: 0.25rem 1rem;
    cursor: pointer;
    background-color: white;
    transition: 300ms ease-in-out;
    border-radius: 6px;
}

.CustomDropdown .dropDownArray.nestedDropDownWrapper .nestedItemsContainer .nestedItems:hover {
    background-color: $gray-light-2-color;
}
.CustomDropdown .dropDownArray.nestedDropDownWrapper .nestedItemsContainer .nestedItems.selected {
    background-color: $gray-light-1-color;
}
.CustomDropdown .dropDownArray.nestedDropDownWrapper .nestedDropDownGroupWrapper:nth-child(1) {
    padding-top: 0.5rem;
}
.CustomDropdown .dropDownArray.nestedDropDownWrapper .nestedDropDownGroupWrapper:nth-last-child(1) {
    padding-bottom: 0.5rem;
}