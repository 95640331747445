$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;

.ChartSettingsLibScrollLock{
    height: 100vh;
    overflow: hidden;
}
.chartPopUp{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    // overflow: hidden;
    z-index: 999;
    background-color: #FFFFFF;
    color: $primary-color;
    opacity: 0;
    transform: scale(0);
    animation: chartPopUp 300ms ease-in-out forwards;
  }

@keyframes chartPopUp {
    0%{
        transform: scale(0);
        opacity: 0;
    }
    100%{
        transform: scale(1);
        opacity: 1;
    }
}

// Buttons

.chartPopUp .primaryButton{
    border: none;
    outline: none;
    background: $blue-color;
    color: $white-color;
    font-weight: 600;
    font-size: .9rem;
    border-radius: 6px;
    height: 3rem;
    padding: 0.5rem 1rem;

    transition: all 200ms ease-in-out;
}
.chartPopUp .secondaryButtonColor{
    background: $blue-light-2-color;
    color: $blue-color;
}
.chartPopUp .secondaryButtonColor:hover{
    color: $white-color;
}
.chartPopUp .primaryButton.secondaryButton:disabled,
.chartPopUp .primaryButton:disabled{
    background: $secondary-color;
}
.chartPopUp .primaryButton:disabled:hover{
    background: $secondary-color;
}

.chartPopUp .primaryButton:hover{
    background: #0091e4;

}
.chartPopUp .primaryButton:active{
    background: #007cc4;

}

// removeButton
.chartPopUp .primaryButton.removeButton , .chartPopUp .primaryButtonSmall.removeButton{
    background: $red-1-color;
    background: $secondary-color;
    // background: $blue-light-1-color;
}
.chartPopUp .primaryButton.removeButton:hover , .chartPopUp .primaryButtonSmall.removeButton:hover{
    background: $red-2-color;
}
.chartPopUp .primaryButton.removeButton:active, .chartPopUp .primaryButtonSmall.removeButton:active{
    background: $red-3-color;
}

.MainLoaderInner div {
  position: absolute;
  animation: MainLoaderInner 1s linear infinite;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  box-shadow: 0 3.6px 0 0 #262323;
  transform-origin: 72px 73.8px;
}
// .MainLoader {
//   width: 200px;
//   height: 200px;
//   display: inline-block;
//   overflow: hidden;
//   background: rgba(255, 255, 255, 0);
// }
// .MainLoaderInner {
//   width: 100%;
//   height: 100%;
//   position: relative;
//   transform: translateZ(0) scale(1);
//   backface-visibility: hidden;
//   transform-origin: 0 0; /* see note above */
// }
// .MainLoaderInner div { box-sizing: content-box; }

// @keyframes MainLoaderInner {
//     0% { transform: rotate(0deg) }
//     50% { transform: rotate(180deg) }
//     100% { transform: rotate(360deg) }
//   }
// Buttons
.chartPopUp .primaryButtonFlexDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 0.25rem;
    gap: 1rem;
    height: 3rem;
}
.chartPopUp .primaryButtonSmall{
    border: none;
    outline: none;
    background: $blue-color;
    color: $white-color;
    padding: 0.2rem 1rem;
    font-size: .9rem;
    border-radius: 6px;
    transition: all 200ms ease-in-out;
}
.chartPopUp .primaryButtonSmall:disabled{
    background: $secondary-color;
}
.chartPopUp .primaryButtonSmall:disabled:hover{
    background: $secondary-color;
}
.chartPopUp .primaryButtonSmall:hover{
    background: #0091e4;
}
.chartPopUp .primaryButtonSmall:active{
    background: #007cc4;
}
.chartPopUp .primaryInputDiv{
    width: 100%;
    padding:  0 .5rem;
    background: $gray-light-1-color;
    border-radius: 6px;

}
.chartPopUp .primaryInput{
    width: 100%;
    padding:  .5rem .75rem;
    background: transparent;
    background: $gray-light-3-color;
    outline: none;
    border: none;
    border-radius: 6px;
    margin: 0.5rem 0;
}
// Divs
.chartPopUp .chartPopUpHeader{
    box-sizing: border-box;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    height: 4rem;
    gap: 1rem;
    border-bottom: 1px solid $gray-light-1-color;
    font-size: 1.5rem;
}
.chartPopUp .chartPopUpHeader .title{
    font-weight: 600;
}
.chartPopUp .chartPopUpHeader .subTitle{
    font-weight: 500;
    color: $secondary-color;
}
.chartPopUp .chartPopUpHeader .goleftSide{
    margin-left: auto;
}
.chartPopUp .chartPopUpHeader .deleteButtonDiv .primaryButton{
    padding: .25rem 2rem;
}
.chartPopUp .chartPopUpHeader .saveButtonDiv .primaryButton{
    padding: .25rem 2rem;
}

.chartPopUp .chartPopUpHeader .closeButton{
    background-color: $gray-light-2-color;
    color: $secondary-color;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    transition: 150ms ease-in-out;
  }
  .chartPopUp .chartPopUpHeader .closeButton:hover{
    background-color: $blue-color;
    color: $white-color;
  }
.chartPopUp .chartPopUpHeader .confirmationContainer{
    position: absolute;
    background-color: $black-hover-color;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chartPopUp .chartPopUpHeader .confirmationContainer .confirmationDiv{
    background-color: white;
    min-width: 10rem;
    padding: 1rem;
    border-radius: 6px;
}
.chartPopUp .chartPopUpHeader .confirmationContainer .confirmationDiv .msg{
    text-align: center;
    margin-bottom: 1rem;
}
.chartPopUp .chartPopUpHeader .confirmationContainer .confirmationDiv .ButtonContainer{
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.chartPopUp .chartPopUpHeader .confirmationContainer .confirmationDiv .ButtonContainer .primaryButtonSmall{
    padding: .5rem 1rem;
}
.chartPopUp .mainContainer{
    height: calc(100vh - 4rem);
    display: flex;
}
$mainColumnWidth:20rem;
$toggleColumnwidth:3rem;
.chartPopUp .mainContainer .firstColumn{
    height: 100%;
    width: 0;
    box-sizing: border-box;
    gap: 0.5rem;
    flex-direction: column;
    display: flex;
    padding: 0 .5rem;
    background: #EEF0F8;
    border-right: 1px solid $gray-light-1-color;
    overflow: hidden;
    animation: firstColumn 300ms forwards ease-in-out ;
}
@keyframes firstColumn {
    0%{
        width: 0;
        opacity: 0;
    }
    100%{
        width: $mainColumnWidth;
        opacity: 1;
    }
}
.chartPopUp .mainContainer .firstColumn.close{
    animation: firstColumnClose 300ms forwards ease-in-out ;
}
@keyframes firstColumnClose {
    0%{
        width: $mainColumnWidth;
        padding: 0 .5rem;
        opacity: 1;
    }
    100%{
        padding: 0;
        width: 0;
        opacity: 0;
    }
}
.chartPopUp .mainContainer .secondColumn{
    height: 100%;
    width: 20rem;
    box-sizing: border-box;
    gap: 0.5rem;
    flex-direction: column;
    display: flex;
    padding: 0 .5rem;
    overflow: auto;
    background: #EEF0F8;
    border-right: 1px solid $gray-light-1-color;
    animation: secondColumn 300ms forwards ease-in-out ;
}
@keyframes secondColumn {
    0%{
        width: 0;
        opacity: 0;
    }
    100%{
        width: $mainColumnWidth;
        opacity: 1;

    }
}
.chartPopUp .mainContainer .secondColumn.close{
    animation: secondColumnClose 300ms forwards ease-in-out ;
}
@keyframes secondColumnClose {
    0%{
        width: $mainColumnWidth;
        padding: 0 .5rem;
        opacity: 1;
    }
    100%{
        padding: 0;
        width: 0;
        opacity: 0;
    }
}
.chartPopUp .mainContainer .thirdColumn {
    padding: 1rem;
    width: calc(100vw  - #{$toggleColumnwidth} );
    transition: 400ms ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.chartPopUp .mainContainer .thirdColumn.oneColumnOpen {
    transition: 400ms ease-in-out;
    width: calc(100vw - #{$mainColumnWidth} - #{$toggleColumnwidth} );
}
.chartPopUp .mainContainer .thirdColumn.twoColumnOpen {
    transition: 400ms ease-in-out;
    width: calc(100vw - #{$mainColumnWidth} - #{$mainColumnWidth} );
}
$fistColumnNonScrollHeight:8.5rem;
//firstColumn
.chartPopUp .mainContainer .firstColumn .staticDiv{
    display: flex;
    flex-direction: column;
    height: $fistColumnNonScrollHeight;
    gap: 0.25rem;
    
}
.chartPopUp .mainContainer .firstColumn .scrollableDiv{
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$fistColumnNonScrollHeight});
    padding: 0 .5rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    overflow: auto;
    gap: 1rem;
}
.chartPopUp .mainContainer .mainTitleDiv{
    display: flex;
    height: 4rem;
    padding: 0 .5rem;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
}
.chartPopUp .mainContainer .mainTitleDiv .arrowIcon{
    cursor: pointer;
}
.chartPopUp .mainContainer .firstColumn .serachBoxOuter{
    width: 100%;
    display: flex;
}
.chartPopUp .mainContainer .firstColumn .searchBox{
    width: calc(100% - 3rem);
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 .5rem;
    height: 4rem;
}
.chartPopUp .mainContainer .firstColumn .CustomDropdown{
    width: 3rem;
    display: flex;
    align-items: center;
    position: relative;
}
.chartPopUp .mainContainer .firstColumn .CustomDropdown .dropDownArray{
    width: 10rem;
    top: 3rem;
    right: 1rem;
}
.chartPopUp .mainContainer .firstColumn .searchBox input[type=text]{
    border-radius: 6px;
    border: none;
    outline: none;
    width: 100%;
    padding: 0.5rem 1rem;
    padding-right: 2.8rem;
}
.chartPopUp .mainContainer .firstColumn .searchBox input[type=text]::placeholder{
    font-weight: 500;
}
.chartPopUp .mainContainer .firstColumn .searchBox .searchIcon{
    position: absolute;
    right: 0.8rem;
}

.chartPopUp .mainContainer .firstColumn  .searchBox .popUpOptions{
    top: 4rem;
}
.chartPopUp .mainContainer .firstColumn  .searchBox .popUpOptions.open{
    max-height: 50vh;
}
.chartPopUp .mainContainer .firstColumn .sourceNameDiv{
    display: flex;
    align-items: center;
    border-radius: 6px;
    // width: 100%;
    padding: 0.5rem 1rem;
    padding-right: 2.8rem;
    background-color: $white-color;
}

.chartPopUp .mainContainer .firstColumn .tablesContainerDiv{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems{
    background: $gray-light-1-color;
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
}

.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItemTitle{
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    display: flex;
    gap: .5rem;
    cursor: pointer;

}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItemTitle .text{
    width: calc( 100% - 1.5rem);
}

.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItemTitle .addButton{
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    transition: 200ms ease-in-out;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItemTitle .addButton:hover{
    background-color: $blue-light-1-color;
    border-radius: 1rem;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItemTitle img{
    width: 1rem;
    // margin-left: auto;
    transition: 200ms ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    height: .5rem;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems.open .tablesItemTitle img{
    transform: rotate(180deg);
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItemList{
    // display: none;
    max-height: 0px;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems.open .tablesItemList{
    display: block;
    max-height: 9999em;
    transition: max-height 3s ease-in-out;

}

.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem{
    background: $white-color;
    margin: 0.5rem 0;
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: .5rem;
    height: 2.5rem;
    padding: 0.5rem .75rem;
    border-radius: 6px;
    position: relative;

    // padding: 0.8rem 1rem;
    transition: 100ms ease-in-out;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem.disable{
    background: $black-hover-color;
    user-select: none;
    transition: 200ms ease-in-out;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem .form-check-input{
    width: 1.5rem;
    height: 1.5rem;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem .draggingSvg{
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $secondary-color;
    font-size: 1.2rem;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem .tablesElemTitle{
    width: calc( 100% - 4.2rem );
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem .tablesElemDataType{
    position: absolute;
    right: .3rem;
    font-size: .8rem;
    font-weight: 500;
    bottom: .1rem;
    // background: $white-color;
    // padding: .20rem;
    border-radius: 6px;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem:hover{
    background: $gray-light-2-color;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem.selected{
    background: $gray-light-2-color;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem .form-check{
    margin-bottom: 0;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem .form-check .form-check-label{
    width: 100%;
}
.chartPopUp .mainContainer .firstColumn .tablesContainerDiv .tablesItems .tablesItem .tableItemTitleDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
}
.chartPopUp .mainContainer .firstColumn .chartFilterByType{
    height: 3.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 0 .5rem;
    padding-left: 1rem;
}
.chartPopUp .mainContainer .firstColumn .chartFilterByType .chartFilterByTypeTitle{
    width: 7rem;
    font-weight: 500;
}

.chartPopUp .mainContainer .firstColumn .chartFilterByType .chartFilterByTypeDropDown{
    width: calc( 100% - 7rem);
}
.chartPopUp .mainContainer .firstColumn .createCustomColumn {
    height: 3rem;
    display: flex;
    // justify-content: flex-end;
    justify-content: center;
    padding: 0 .5rem;
}
// secondColumn
.chartPopUp .mainContainer .secondColumn .staticDiv{
    display: flex;
    flex-direction: column;
    height: calc(13rem + 3.5rem);
    gap: 0.5rem;
}
.chartPopUp .mainContainer .secondColumn .staticDiv.WithoutStyleToggle{
    height: calc(7rem + 3.5rem);
}
.chartPopUp .mainContainer .secondColumn .scrollableDiv{
    display: flex;
    flex-direction: column;
    height: calc(100% - 13rem - 3.5rem);
    padding: 1rem .5rem;
    overflow: auto;
    gap: 1rem;
}
.chartPopUp .mainContainer .secondColumn .scrollableDiv.WithoutStyleToggle{
    height: calc(100% - 7rem - 3.5rem);
}

.chartPopUp .mainContainer .secondColumn .MainTitle{
    font-weight: 500;
    font-size: 1.4rem;
    height: 2rem;
    margin-bottom: 0.5rem;
    transition: 300ms ease-in-out;
}
.chartPopUp .mainContainer .secondColumn .MainTitle.disable{
    color: $secondary-color;
}
.chartPopUp .mainContainer .secondColumn .MainTitle.inFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chartPopUp .mainContainer .secondColumn .MainTitle .resetButton{
    width: 2rem;
    aspect-ratio: 1;
    background-color: $white-color;
    justify-content: center;
    display: flex;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;
    transition: 300ms ease-in-out;
}
.chartPopUp .mainContainer .secondColumn .MainTitle .resetButton:hover{
    background-color: $gray-light-1-color;
}

.chartPopUp .mainContainer .secondColumn .MainTitle .resetButton img{
    height: 65%;
}
.chartPopUp .mainContainer .secondColumn .chartWidth{
    height: 3.5rem;
    padding: 0 .5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
}
.chartPopUp .mainContainer .secondColumn .chartWidth .chartWidthTitle{
    width: 8rem;
    font-weight: 500;
}

.chartPopUp .mainContainer .secondColumn .chartWidth .chartWidthDropDown{
    width: calc( 100% - 8rem);
}
.chartPopUp .mainContainer .secondColumn .toggleButtonContainer{
    display: flex;
    height: 5rem;
    align-items: baseline;
    font-weight: 500;
    font-size: 1.4rem;
    margin-top: .5rem;
    padding: 0 .5rem;
}
.chartPopUp .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv{
    display: flex;
    background: #ffffff99;
    border-radius: 4px;
}
.chartPopUp .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton{
    display: flex;
    flex-direction: column;
    width: 6.5rem;
    height: 4.5rem;
    justify-content: space-evenly;
    text-align: center;
    border-radius: 4px;
    font-size: 1.2rem;
    z-index: 2;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.chartPopUp .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton.active{
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    background: #FFFFFF;
}
.chartPopUp .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton.disable{
    color: #7E8299;
    filter: grayscale(100%);
}
.chartPopUp .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton img{
    height: 1.8rem;
}
.chartPopUp .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton.style img{
    height: 1.5rem;
    margin-top: 0.3rem;
}


// axisInnerDivAdd
.chartPopUp .mainContainer .secondColumn .textAndToggle {
    display: flex;
    height: 2.5rem;
    font-weight: 500;
    padding-left: 1rem;
    align-items: center;
}
.chartPopUp .mainContainer .secondColumn .textAndToggle .toggleDiv{
    margin-left: auto;
}

.chartPopUp .mainContainer .thirdColumn .chartNameDiv {
    display: grid;
    gap: 1rem;
    grid-template-columns: 10rem 1fr;
    align-items: center;
}

.chartPopUp .mainContainer .thirdColumn .chartNameDiv .titleDiv{
    font-weight: 500;
}
.chartPopUp .mainContainer .thirdColumn .chartNameDiv input{
    width: 100%;
    padding: 0.5rem 0.75rem;
    background: transparent;
    background: #F5F8FA;
    outline: none;
    border: none;
    border-radius: 6px;
}
.chartPopUp .mainContainer .thirdColumn .chartDescriptionDiv {
    display: grid;
    gap: 1rem;
    grid-template-columns: 10rem 1fr;
    align-items: center;
}

.chartPopUp .mainContainer .thirdColumn .chartDescriptionDiv .titleDiv{
    font-weight: 500;
}
.chartPopUp .mainContainer .thirdColumn .chartDescriptionDiv textarea{
    width: 100%;
    padding: 0.5rem 0.75rem;
    background: transparent;
    background: #F5F8FA;
    outline: none;
    border: none;
    border-radius: 6px;
    resize: none;
    line-height: 1.5;
}
.chartPopUp .mainContainer .thirdColumn .chartWidthOuterDiv {
    display: flex;
    // align-items: center;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1rem;
    
}
.chartPopUp .mainContainer .thirdColumn .chartWidthDiv {
    display: grid;
    gap: 1rem;
    grid-template-columns: 7rem 10rem;
    place-items: center;
    align-items: center;
    @media (max-width: 1100px) {
        width: 10rem;
        height: 5rem;
        display: block;
    }
}

.chartPopUp .mainContainer .thirdColumn .chartWidthDiv .titleDiv{
    font-weight: 500;
    @media (max-width: 1100px) {
        text-align: center;
        span{
            display: none;
        }
    }
}
.chartPopUp .mainContainer .thirdColumn .chartWidthDiv .chartWidthDropDown{
    width: 100%;
}
.chartPopUp .mainContainer .thirdColumn .chartLoadingArea {
    width:  100%;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chartPopUp .mainContainer .thirdColumn .chartPlotArea {
    overflow: auto;
    position: relative;
}
.chartPopUp .mainContainer .thirdColumn .chartPlotArea .card {
    border: 1px solid $black-hover-color;
}
.chartPopUp .mainContainer .thirdColumn .flotingOptions {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    z-index: 5;
    gap: 1rem;
    align-items: center;
}
.chartPopUp .mainContainer .thirdColumn .fullScreenIcon {
    background-color: $gray-light-3-color;
    background-color: $blue-light-2-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1.5rem;
    margin: 0.5rem 0;
    transition: 100ms ease-in-out;
    color: $blue-color;
    // box-shadow: inset 0 0 6px -1px $black-hover-color;`
}
.chartPopUp .mainContainer .thirdColumn .fullScreenIcon:hover {
    // background-color: $gray-light-2-color;
    color: $white-color;
    background-color: #0091e4;
}
.chartPopUp .mainContainer .thirdColumn .fullScreenIcon:active {
    // background-color: $gray-light-1-color;
    color: $white-color;
    background-color: #007cc4;

}
.chartPopUp .mainContainer .thirdColumn .chartPopulateButtonDiv {
    flex-direction: column;
    gap: 1rem;
}
.chartPopUp .mainContainer .thirdColumn .chartPopulateButtonDiv .primaryButton{
    padding: .5rem 2rem;
}

