$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;


.chartPopUp .mainContainer .legendPopupDivOuterContainer{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 2;
    width: 100vw;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chartPopUp .mainContainer .legendButton{
    width: 100%;
    margin-top: 1rem;
}
.chartPopUp .mainContainer .legendButton .primaryButton{
    width: 100%;
}

.chartPopUp .mainContainer .legendPopupDivInnerContainer{
    min-width: 30%;
    max-width: 80%;
    min-height: 30%;
    max-height: 80%;
    overflow: auto;
    border-radius: 6px;
    background: $white-color;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding-top: 0.75rem;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .closeButton{
    font-weight: 600;
    margin-left: auto;
    box-sizing: border-box;
    height: 2rem;
    width: 2rem;
    display: flex;
    background: #7e829999;
    box-shadow: 0px 2px 20px #0000001a;
    justify-content: center;
    color: $white-color;
    align-items: center;
    border-radius: 4rem;
    cursor: pointer;
    transition: 200ms ease-in-out;
    user-select: none;
    // margin-bottom: .25rem;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .closeButton{
    background: $blue-light-1-color;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .closeButton img{
    height: 1.5rem;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .legendPopupItems{
    display: grid;
    grid-template-columns: .5rem 1fr 1fr 1fr 1fr;
    border-radius: 6px;
    background: $white-color;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer.legendPopupColumn .legendPopupItems{
    display: grid;
    grid-template-columns: .5rem 1fr 1fr 1fr;
    border-radius: 6px;
    background: $white-color;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .legendPopupItems.upper{
    background-color: $gray-light-2-color;
    padding: 0 .5rem;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .legendPopupScrollableDiv{
    background-color: #EFF0F7;
    padding: 0.5rem 0.5rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    height: calc( 100% - 3rem );
    min-height: 10rem;
    overflow: auto;
    gap: 0.5rem;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .legendPopupItem{
    display: flex;
    align-items: center;
    padding: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .legendPopupItem.serialNumber{
    justify-content: center;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .legendPopupItem.Opacity{
    // padding-right: 2rem;
    flex-direction: row-reverse;
    gap: 2rem;
    overflow: inherit;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .colorSelector .colorInputDiv{
    margin-bottom: 0;
}
.chartPopUp .mainContainer .legendPopupDivInnerContainer .colorSelector .colorInputDiv{
    margin-bottom: 0;
}
.chartPopUp .opacityOuterDiv .opacityRange {
    width: 80%;
    margin-left: 10%;
}