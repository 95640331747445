$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;



.KpiDashboard{

}



// Buttons
.KpiDashboard .primaryButton{
  border: none;
  outline: none;
  background: $blue-color;
  color: $white-color;
  font-weight: 600;
  font-size: .9rem;
  border-radius: 6px;
  height: 3rem;
  padding: 0.5rem 1rem;

  transition: all 200ms ease-in-out;
}
.KpiDashboard .secondaryButtonColor{
  background: $blue-light-2-color;
  color: $blue-color;
}
.KpiDashboard .secondaryButtonColor:hover{
  color: $white-color;
}
.KpiDashboard .primaryButton.secondaryButton:disabled,
.KpiDashboard .primaryButton:disabled{
  background: $secondary-color;
}
.KpiDashboard .primaryButton:disabled:hover{
  background: $secondary-color;
}

.KpiDashboard .primaryButton:hover{
  background: #0091e4;

}
.KpiDashboard .primaryButton:active{
  background: #007cc4;

}

// removeButton
.KpiDashboard .primaryButton.removeButton , .KpiDashboard .primaryButtonSmall.removeButton{
  background: $red-1-color;
  background: $secondary-color;
}
.KpiDashboard .primaryButton.removeButton:hover , .KpiDashboard .primaryButtonSmall.removeButton:hover{
  background: $red-2-color;
}
.KpiDashboard .primaryButton.removeButton:active, .KpiDashboard .primaryButtonSmall.removeButton:active{
  background: $red-3-color;
}

.KpiDashboard .kpiDashboardHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: .5rem 1rem;
  border-radius: 6px;
  background-color: $white-color;
}
.KpiDashboard .kpiDashboardHeader .selectionContainer{
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.KpiDashboard .kpiDashboardHeader .selectionContainer .selectInnerContainer{
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
  .KpiDashboard .kpiDashboardHeader .selectionContainer .headerTitle{
  display: flex;
  align-items: center;
  font-weight: 500;
  
}
  .KpiDashboard .kpiDashboardHeader .selectDepartment{
  width: 16rem;
}
.KpiDashboard .kpiDashboardHeader .selectCircle{
  width: 16rem;
}
.KpiDashboard .kpiDashboardChartContainer{
 margin-top: 1rem;
}