$white-color:white;
$Light-Blue-1-Color:#c2daff;
$Light-Blue-2-Color:#aec8f0;


$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color:#155724;
$green-2-color:#c3e6cb;
$green-3-color:#d4edda;



.DataConnectors .dataLakeCard{
  min-height: 188px;
  background-position: right top;
  background-size: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.DataConnectors .dataLakeCard .supistaCardTitle{
  display: block;
  width: calc( 100% - 2.5rem);
}
.DataConnectors .dataLakeCard .supistaCardSettingButton{
  position: absolute;
  top: 1.5rem;
  right: 5px;
}
.DataConnectors .dataLakeCard .importTableButton{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -1.5rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}
