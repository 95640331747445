$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;




.chartPopUp .mainContainer .secondColumn .CardHeaderImage .cardHeaderImageContainer{
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    // max-height: 8rem;
    transition: 300ms ease-in-out;
    width: 100%;
    height: 100%;
    height: 8rem;
    object-fit: contain;
    color: $blue-light-1-color;
    font-size: 1.25rem;
}
.chartPopUp .mainContainer .secondColumn .CardHeaderImage .cardHeaderImageContainer .loadingImage{
    position: absolute;
    width: 15rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-color;
    border-radius: 6px;
}
.chartPopUp .mainContainer .secondColumn .CardHeaderImage .cardHeaderImageContainer .invalidImage{
    position: absolute;
    width: 15rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-color;
    border-radius: 6px;
}
.chartPopUp .mainContainer .secondColumn .CardHeaderImage .cardHeaderImageContainer img{
    width: 80%;
    height: 80%;
    object-fit: contain;
}
.chartPopUp .mainContainer .secondColumn .CardHeaderImage  input{
    width: calc(100% - 1.5rem);
    padding:  .5rem .75rem;
    // color: $blue-light-1-color;
    background: transparent;
    background: $gray-light-3-color;
    outline: none;
    border: none;
    border-radius: 6px;
}
.chartPopUp .mainContainer .secondColumn .CardHeaderImage .invalidImage{
    display: flex;
    justify-content: center;
    align-items: center;
    // max-height: 8rem;
    transition: 300ms ease-in-out;
    width: 100%;
    height: 100%;
    height: 8rem;
    object-fit: contain;
}
.chartPopUp .mainContainer .secondColumn .CustomDropdown.noMargin .mainTitle{
    margin: 0;
}
.chartPopUp .mainContainer .secondColumn .KpiTargetPointColorContainer{
    margin: .5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.chartPopUp .mainContainer .secondColumn .KpiTargetPointColorContainer .KpiTargetPointColorDiv{
    display: flex;
    justify-content: space-between;
}
.chartPopUp .mainContainer .secondColumn .KpiTargetPointColorContainer .KpiTargetPointColorDiv .colorDropperInput{
    aspect-ratio: 1;
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}
.chartPopUp .mainContainer .secondColumn .KpiTargetPointColorContainer .KpiTargetPointColorDiv .colorDropperInput input{
    width: 120%;
    height: 120%;
    padding: 0;
    opacity: 0;
}
.chartPopUp .mainContainer .secondColumn .kpiTargetPointsRemove {
    margin: auto;
    display: flex;
}

.chartPopUp .mainContainer .secondColumn .barStackLineTargetPointsDiv{
    // font-size: 1.2rem;
    background-color: $gray-light-1-color;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: .0rem .5rem;
    margin: .25rem 0;
    border-radius: 6px;
}
.chartPopUp .mainContainer .secondColumn .barStackLineTargetPointsDiv .titleDiv.barStackLineTitleDiv{
    width: 6rem;
}
.chartPopUp .mainContainer .secondColumn .barStackLineTargetPointsDiv .nestedTitleDiv{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}