$white-color:white;
$Light-Blue-1-Color:#c2daff;
$Light-Blue-2-Color:#aec8f0;


$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color:#155724;
$green-2-color:#c3e6cb;
$green-3-color:#d4edda;




.DisplayTable .tableHeaderDiv .closeDiv{
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.DisplayTable .scrollingPart {
    max-height: 65vh;
    overflow: auto;
}
.DisplayTable .tableDivContainer {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: .5rem 0;
    height: 100%;
    overflow: auto;
    // background-color: $gray-light-3-color;
    // gap: 1rem;
}

.DisplayTable .tableDivContainer .tableOuterDiv {
    // background-color: $gray-light-1-color;
    border: solid 1px $black-hover-color;
    margin: 0.25rem 0.5rem;
    min-height: 2.5rem;
    border-radius: 6px;
    // display: flex;
    padding: .5rem;
}

.DisplayTable .tableDivContainer .tableOuterDiv .tableName{
    // width: 3rem;
    // writing-mode: vertical-lr;
    // position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    font-size: 1.2rem;
    font-weight: 500;
}
.DisplayTable .tableDivContainer .tableOuterDiv .tableInnerDiv{
    width: 100%;
    // background-color: $gray-light-3-color;
    padding: .5rem;
    border-radius: 6px;
    overflow: auto;
    display: flex;
        justify-content: stretch;
}

.DisplayTable .tableDivContainer .tableOuterDiv .columnDiv{
    background-color: $gray-light-3-color;
    border-radius: 6px;
        width: 100%;
}
.DisplayTable .tableDivContainer .tableOuterDiv .columnDiv:nth-last-child(1){
    border-right: solid 1px $black-hover-color;
}
.DisplayTable .tableDivContainer .tableOuterDiv .columnName{
    background-color: $secondary-color;
    background-color: $primary-color;
    color: $white-color;
    justify-content: center;
    display: flex;
    font-weight: 500;
    padding: 0.5rem 1rem;
    letter-spacing: .4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.DisplayTable .tableDivContainer .tableOuterDiv .columnName.highlightedColumnName{
    background-color: $green-3-color;
    color: $green-1-color;
}
.DisplayTable .tableDivContainer .tableOuterDiv .columnValues{
    border-left: solid 1px $black-hover-color;
    padding: 0.5rem 1rem;
    overflow: hidden;
    height: 2.4rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.DisplayTable .tableDivContainer .tableOuterDiv  .columnValues:nth-child(2n+2){
    background-color: $gray-light-1-color;
}
.DisplayTable .tableDivContainer .tableOuterDiv  .columnValues:nth-last-child(1){
    border-bottom: solid 1px $black-hover-color;
}
.DisplayTable .tableDivContainer .tableOuterDiv .columnDiv:nth-last-child(1) .columnValues:nth-last-child(1){
    border-radius: 0 0 6px 0;
}
.DisplayTable .tableDivContainer .tableOuterDiv .columnDiv:nth-child(1) .columnValues:nth-last-child(1){
    border-radius: 0 0 0 6px;
}
.DisplayTable .tableDivContainer .tableOuterDiv .columnDiv:nth-last-child(1) .columnName{
    border-radius: 0 6px 0  0;
}
.DisplayTable .tableDivContainer .tableOuterDiv .columnDiv:nth-child(1) .columnName{
    border-radius: 6px 0 0 0;
}

.DisplayTable .tableDivContainer .tableOuterDiv .moreButton{
    display: flex;
    justify-content: center;
}
.DisplayTable .tableDivContainer .tableOuterDiv .moreButton span{
    color: $blue-color;
    padding: .5rem;
    cursor: pointer;
}