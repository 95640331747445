
$white-color:white;
$Light-Blue-1-Color:#c2daff;
$Light-Blue-2-Color:#aec8f0;


$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color:#155724;
$green-2-color:#c3e6cb;
$green-3-color:#d4edda;



.SearchInputDiv{
  // width: calc(100% - 3rem);
  width: 18rem;
  position: relative;
  display: flex;
  align-items: center;
  // padding: 0 0.5rem;
  // height: 3rem;
  border-radius: 6px;
  margin: 0 .5rem;
}
.SearchInputDiv.SearchInputDivBorder{
  border: 1px solid $black-hover-color; 
}
.SearchInputDiv.SearchInputDivFullWidth{
  width: 100%;
  margin: 0;
}
.SearchInputDiv input[type=text] {
  border-radius: 6px;
  border: none;
  outline: none;
  width: 100%;
  padding: 0.5rem 1rem;
  padding-right: 2.5rem;
}
.SearchInputDiv input[type=text]::placeholder{
  font-weight: 500;
}
.SearchInputDiv .searchIcon {
  position: absolute;
  right: 0.5rem;
  font-size: 1.2rem;
  color: $secondary-color;
  display: flex;
  justify-content: center;
  align-items: center;
}