$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;


.ReportSettings .pagesOuterWrapper{
  width: 100%;
  height: 100%;
  // background-color: $blue-light-2-color;
  padding: 1rem;
}
.ReportSettings .pagesWrapper{
  border-radius: 6px;
  width: 100%;
  height: 100%;
  background-color: $gray-light-2-color;
  padding: .25rem;
}
.ReportSettings .pagesWrapper .pageHeader{
 display: flex;
 height: 3rem;
 align-items: center;
}

.ReportSettings .pagesWrapper .pageHeader .pagesTitle{
  font-weight: 600;
  height: 100%;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReportSettings .pagesWrapper .pageHeader .addPages{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin: 0 .5rem ;
  margin-right: .5rem;
  position: relative;
  font-size: 1.25rem;
 
}
.ReportSettings .pagesWrapper .pageHeader .addPages .addPagesButton{
  height: 2rem;
  width:  2rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.25rem;
  overflow: hidden;
  color: $secondary-color;
  transition: 100ms ease-in-out;
  &:hover{
    color: $blue-color;
    background-color: $blue-light-2-color;
  }
}
.ReportSettings .pagesWrapper .pageHeader .scrollButoonDivContainer{
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: 500ms ease-in-out;
}
.ReportSettings .pagesWrapper .pageHeader .scrollButoonDivContainer .scrollButoonDiv{
  height: 1.5rem;
  width:  1.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  overflow: hidden;
  color: $secondary-color;
  transition: 100ms ease-in-out;
  &:hover{
    color: $blue-color;
    background-color: $blue-light-2-color;
  }
  &.scrollButoonDivDisable{
    color: $gray-light-1-color;
    cursor: auto;
    &:hover{
    color: $gray-light-1-color;
    background-color: transparent;
    }
  }
}
.ReportSettings .pagesWrapper .pageHeader .pagesScrollOuter{
  width: calc( 100% - 5rem  - 3rem - 4rem );
  overflow: auto;
  overflow: hidden;
  height: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
}

$slectedBorderRadius:10px;
$slectedBorderBackgroundColor:$gray-light-2-color;
.ReportSettings .pagesWrapper .pageHeader .pagesScrollOuter .pagesScrollElem{
  height: 100%;
  display: flex;
  align-items: center;
  padding: .5rem 1.5rem;
  margin: 0 .5rem ;
  border-radius: $slectedBorderRadius $slectedBorderRadius 0 0 ;
  position: relative;
  cursor: pointer;
  color: $secondary-color;
  text-wrap: nowrap;
}
.ReportSettings .pagesWrapper .pageHeader .pagesScrollOuter .pagesScrollElem.selectedPage{
  background-color: $white-color;
  color: $primary-color;
}
.ReportSettings .pagesWrapper .pageHeader .pagesScrollOuter .selectedPage .selectedPageOptions{
  width: 1rem;
  margin-left: 1rem;
}
.ReportSettings .pagesWrapper .pageHeader .pagesScrollOuter .selectedPage .selectedPageOptions .mainTitle{
  background-color: $white-color;
  padding: 0;
}

  .ReportSettings .pagesWrapper .pageHeader .pagesScrollOuter .pagesScrollElem .left-curve {
  position: absolute;
  bottom: 0px;
  left: -$slectedBorderRadius;
  height: 100%;
  width: $slectedBorderRadius;
  background: $white-color;
  transition: 50ms ease-in-out;
  opacity: 0;
  // display: none;
  &::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: $slectedBorderRadius;
    background: $slectedBorderBackgroundColor;
  }
}

.ReportSettings .pagesWrapper .pageHeader .pagesScrollOuter .pagesScrollElem .right-curve {
  position: absolute;
  right: -$slectedBorderRadius;
  top: 0px;
  height: 100%;
  width: $slectedBorderRadius;
  background: $white-color;
  transition: 50ms ease-in-out;
  // display: none;
  opacity: 0;
  &::before {
    content: "";
    right: 0;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    border-bottom-left-radius: $slectedBorderRadius;
    background: $slectedBorderBackgroundColor;
  }
}

.ReportSettings .pagesWrapper .pageHeader .pagesScrollOuter .pagesScrollElem.selectedPage .left-curve,
.ReportSettings .pagesWrapper .pageHeader .pagesScrollOuter .pagesScrollElem.selectedPage .right-curve {
  // display: block;
  opacity: 1;
}

.ReportSettings .pagesWrapper .pageHeader .dividerDiv{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReportSettings .pagesWrapper .pageHeader .dividerDiv hr{
  height: 60%;
  width: 1px;
  margin: 0 ;
  background-color: $blue-light-1-color;
}

.ReportSettings .pagesWrapper .pageInnerContent{
  display: flex;
  height: calc( 100% - 3rem );
  background-color: $white-color;
  border-radius: 6px;
  gap: 1rem;
  padding: 1rem;
}
.ReportSettings .pagesWrapper .pageInnerContent .pageLeftContainer{
  width: 25rem;
}
.ReportSettings .pagesWrapper .pageInnerContent .pageRightContainer{
  width: calc( 100% - 25rem - 1rem );
  height: 100%;
}
.ReportSettings .pagesWrapper .pageInnerContent .pageRightContainer .tableCustomColumn{
  height: 100%;
}
.ReportSettings .pagesWrapper .pageInnerContent .pageRightContainer .customColumnOuterArea{
  height: 100%;
  padding: 1rem;
  border: dashed 1px $gray-light-1-color;
  border-radius: 6px;
}
.ReportSettings .pagesWrapper .pageInnerContent .pageRightContainer .pageRightNoTableSelectDiv{
  height: 100%;
  padding: 2rem;
  border: dashed 1px $gray-light-1-color;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $gray-light-3-color;
}
.ReportSettings .pagesWrapper .pageInnerContent .pageRightContainer .pageRightNoTableSelectDiv .pageRightNoTableSelectTitleDiv{
  font-weight: 500;
  font-size: 1.5rem;
  color: $secondary-color;
  text-align: center;
}
.ReportSettings .pagesWrapper .pageInnerContent .pageRightContainer .pageRightNoTableSelectDiv .pageRightNoTableSelectDiscriptionDiv{
  // font-weight: 500;
  font-size: 1rem;
  color: $secondary-color;
  text-align: center;
}
