$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;

.DashboardSettings .fontStyleDiv{
    display: flex;
    align-items: center;
    gap: .5rem;
    margin: .5rem 0;
}
.DashboardSettings .fontStyleDiv .fontStyleSquare{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    aspect-ratio: 1;
    border-radius: 6px;
    background-color: $gray-light-3-color;
    font-size: 1.3rem;
    user-select: none;
    cursor: pointer;
    transition: 300ms ease-in-out;
}
.DashboardSettings .fontStyleDiv .fontStyleSquare:hover{
    background-color: $gray-light-2-color;

}
.DashboardSettings .fontStyleDiv .fontStyleSquare.fontStyleItalic{
    font-style: italic;
    font-family: 'Times New Roman';
}
.DashboardSettings .fontStyleDiv .fontStyleSquare.fontStyleUnderLine{
    text-decoration-line: underline;
}
.DashboardSettings .fontStyleDiv .fontStyleSquare b{ 
    color: $blue-color;
}
