
.ReportSettings .copyColumnToClipBoard{
  position: relative;
  cursor: pointer;
  word-wrap: break-word;
  width: 100%;
}
.ReportSettings .copyColumnToClipBoard .copyColumnToClipBoardIcon{
  position: absolute;
  right: -1rem;
  top: -.25rem;
  font-size: .9rem;
  opacity: 0;
  // opacity: 1;
}
.ReportSettings .copyColumnToClipBoard:hover .copyColumnToClipBoardIcon{
  opacity: 1;
}