$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;

.PopUpDivChild.WarningPopupContainer .WarningPopupContainerpopUpOuterContaier .WarningPopupContainerPopUpInnerContaier{
  // display: grid;
  // place-items: center; 
  min-height: auto;   
  min-width: 30%;
  // min-width: auto;
  max-width: 35%;
}
.WarningPopupContainer .WarningPopupContainerpopUpOuterContaier  .WarningPopupContainerChildernContainer{
  display: grid;
  gap: .5rem;
}
.WarningPopupContainer .WarningPopupContainerpopUpOuterContaier  .warningIcon{
  display: flex;
  justify-content: center;
  font-size: 4rem;
  color: #FFC700;
}
.WarningPopupContainer .WarningPopupContainerpopUpOuterContaier  .warningTitleDiv{
 display: flex;
 height: 2rem;
 justify-content: space-between;
 justify-content: center;
 align-items: center;
 font-weight: 500;
 font-size: 1.1rem;
 padding: 0 0.5rem;
}
.WarningPopupContainer .WarningPopupContainerpopUpOuterContaier  .discDiv{
 text-align: center;
 color: $red-1-color;
}
.WarningPopupContainer .WarningPopupContainerpopUpOuterContaier  .primaryButtonFlexDiv{
  display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: 3rem;
  margin-top: .5rem;
}
.WarningPopupContainer .WarningPopupContainerpopUpOuterContaier  .primaryButton{
  padding: 0.5rem 2rem;
}
.WarningPopupContainer .WarningPopupContainerpopUpOuterContaier  .primaryButton.cancleButtonColor{
  background-color: $blue-light-1-color;
}
.WarningPopupContainer .WarningPopupContainerpopUpOuterContaier  .primaryButton.cancleButtonColor:hover{
  background-color: $gray-light-1-color;
  background-color: $secondary-color;
}