$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;



.timeIntervalFieldContainer{
  padding: 0 1rem;
  display: grid;
  gap: .5rem;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer{
  display: grid;
  grid-template-columns: 1rem 1fr;
  gap: 1rem;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalTypeCheckbox{
  cursor: pointer;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalType .timeIntervalTypeTitle{
  cursor: pointer;
  font-weight: 500;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalType .timeIntervalOptionsContainer{
  display: grid;
  gap: .5rem;
  padding: .5rem;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalType .timeIntervalOptionsContainer .timeIntervalOption{
  display: grid;
  gap: .5rem;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalType .timeIntervalOptionsContainer .timeIntervalOption.timeIntervalOptionInFlex{
  display: flex;
  align-items: center;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalType .timeIntervalOptionsContainer .timeIntervalOption .CustomDropdown .dropDownArray.open{
  max-height: 10em;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalType .timeIntervalOptionsContainer .timeIntervalTitle{
  font-weight: 500;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalType .timeIntervalOptionsContainer .multiSelectBoxContainer{
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(10,2.5rem);
}

.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalType .timeIntervalOptionsContainer .multiSelectBoxContainer .multiSelectBoxOption{
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  color: $secondary-color;
  background-color: $gray-light-3-color;
  transition: 100ms ease-in-out;
}
.timeIntervalFieldContainer .timeIntervalTypeContainer .timeIntervalType .timeIntervalOptionsContainer .multiSelectBoxContainer .multiSelectBoxOption.selectedMultiSelectBoxOption{
  color: $blue-color;
  background-color: $blue-light-2-color;
  font-weight: 500;
}