$white-color:white;
$Light-Blue-1-Color:#c2daff;
$Light-Blue-2-Color:#aec8f0;


$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;



.ChatBot .chartPopUp .chartPopUpHeader .deleteButtonDiv{
    display: none;
}
.ChatBot{
    position: relative;
}
.ChatBot .chatArea{
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
    padding-bottom: 4rem;
}
.ChatBot .primaryButton{
    border: none;
    outline: none;
    background: $blue-color;
    color: $white-color;
    font-weight: 600;
    font-size: .9rem;
    border-radius: 6px;
    height: 3rem;
    padding: 0.5rem 1rem;
    transition: all 200ms ease-in-out;
}
.ChatBot .secondaryButtonColor{
    // background: $secondary-color;
    // background: $white-color;
    background: $blue-light-2-color;
    // color: $primary-color;
    color: $blue-color;
    // border: solid 1px $blue-light-1-color;
    // border-color: #f1faff;
    // background-color: #f1faff;
    // background: $white-color;
}
.ChatBot .secondaryButtonColor:hover{
    color: $white-color;
}
.ChatBot .primaryButton:disabled{
    background: $secondary-color;
}
.ChatBot .primaryButton:disabled:hover{
    background: $secondary-color;
}

.ChatBot .primaryButton:hover{
    background: #0091e4;
}
.ChatBot .primaryButton:active{
    background: #007cc4;
}

// removeButton
.ChatBot .primaryButton.removeButton , .ChatBot .primaryButtonSmall.removeButton{
    background: $red-1-color;
    background: $secondary-color;
    // background: $blue-light-1-color;
}
.ChatBot .primaryButton.removeButton:hover , .ChatBot .primaryButtonSmall.removeButton:hover{
    background: $red-2-color;
}
.ChatBot .primaryButton.removeButton:active, .ChatBot .primaryButtonSmall.removeButton:active{
    background: $red-3-color;
}
// Buttons
.ChatBot .primaryButtonFlexDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 0.25rem;
    gap: 1rem;
    height: 3rem;
}
.ChatBot .primaryButtonSmall{
    border: none;
    outline: none;
    background: $blue-color;
    color: $white-color;
    padding: 0.2rem 1rem;
    font-size: .9rem;
    border-radius: 6px;
    transition: all 200ms ease-in-out;
}
.ChatBot .primaryButtonSmall:disabled{
    background: $secondary-color;
}
.ChatBot .primaryButtonSmall:disabled:hover{
    background: $secondary-color;
}
.ChatBot .primaryButtonSmall:hover{
    background: #0091e4;
}
.ChatBot .primaryButtonSmall:active{
    background: #007cc4;
}
.ChatBot .primaryInput{
    width: 100%;
    padding:  .5rem .75rem;
    background: transparent;
    background: $gray-light-3-color;
    outline: none;
    border: none;
    border-radius: 6px;
    margin: 0.5rem 0;
}