$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;


//StyleSection


.KpiChartSettings .mainContainer .outerDiv .grayContainers{
    display: flex;
    flex-direction: column;
    background: $gray-light-1-color;
    border-radius: 6px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.KpiChartSettings .mainContainer .outerDiv.open .grayContainers{
    min-height: 3rem;
    max-height: 999em;
    transition: max-height 300ms ease-in-out;
}
.KpiChartSettings .mainContainer .outerDiv.open .grayContainers.noOverflowGrayContainer{
    overflow: initial;
}
.KpiChartSettings .mainContainer .outerDiv.open .grayContainers:hover {
    overflow: initial;
}
.KpiChartSettings .mainContainer .outerDiv.open .grayContainers .resetDefault{
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;
    user-select: none;
}
.KpiChartSettings .mainContainer .outerDiv .grayContainers .containerItems{
    background-color: $white-color;
    margin: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
    // gap: .5rem;
    min-height: 2.5rem;
    padding: 0.5rem 1rem;
    border-radius: 6px;
}
.KpiChartSettings .mainContainer .outerDiv .grayContainers .containerItems:nth-child(1){
    margin-top: .5rem;
}
.KpiChartSettings .mainContainer .outerDiv .grayContainers .containerItems:nth-last-child(1){
    margin-bottom: .5rem;
}
.KpiChartSettings .mainContainer .outerDiv .grayContainers .containerItems .titleDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
}
.KpiChartSettings .mainContainer .outerDiv .grayContainers .containerItems .titleDiv span {
    margin-right: auto;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
.KpiChartSettings .mainContainer .outerDiv .grayContainers .containerItems .titleDiv.cursorPointer{
    cursor: pointer;
}
.KpiChartSettings .mainContainer .outerDiv .grayContainers .containerItems .titleDiv img{
    cursor: pointer;
    user-select: none;
    height: .5rem;
    transition: 200ms ease-in-out;
}

.KpiChartSettings .mainContainer .outerDiv .grayContainers .containerItems.open .titleDiv img{
    transform: rotate(180deg);
}
.KpiChartSettings .mainContainer .outerDiv .grayContainers .containerItems .titleDiv .mainContainerTitle{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .grayContainers .containerItems .containerArrow{
    cursor: pointer;
    user-select: none;
    // height: .5rem;
    transition: 200ms ease-in-out;
}

.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .grayContainers .containerItems.open .containerArrow{
    transform: rotate(180deg);
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .grayContainers .containerItems .contaierClosableDiv{
    max-height: 0px;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .grayContainers .containerItems .contaierClosableDiv:hover{
    overflow: initial;
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .grayContainers .containerItems.open .contaierClosableDiv{
    display: block;
    max-height: 999em;
    padding-top: 0.5rem;
    transition: max-height 300ms ease-in-out;
}
// .KpiChartSettings .mainContainer .scrollableDiv .outerDiv .grayContainers .containerItems select{
//     background-color: $gray-light-3-color;
//     height: 2.5rem;
//     border-radius: 6px;
//     width: 100%;
//     padding: 0.5rem 1rem;
//     outline: none;
//     color: $primary-color;
//     border: none;
// }
// colorPalettes
.KpiChartSettings .colorSelector .titleDiv{
    margin: .5rem 0;
}
.KpiChartSettings .colorSelector .colorPalettesOuterContainer{
    margin-bottom: 1rem;
}
.KpiChartSettings .colorSelector .colorPalettesOuterContainer .colorPalettesInnerContainer{
    display: grid;
    gap: .25rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    
}

.KpiChartSettings .colorSelector .colorPalettesOuterContainer .colorPalettesInnerContainer .palette{
    width: 100%;
    aspect-ratio: 1;
    content: "";
    border-radius: 6px;
    transition: 300ms ease-in-out;
    &:hover {
        cursor: pointer;
        box-shadow: inset 0 0 0 .1rem $white-color;
    }

}

.KpiChartSettings .colorSelector .colorInputDiv{
    background: $gray-light-3-color;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    margin-bottom: 1rem;
}
.KpiChartSettings .colorSelector .colorInputDiv input{
    outline: none;
    border: none;
    background: transparent;
}
.KpiChartSettings .colorSelector .colorInputDiv .colorHashInput{
    width: calc( 100% - 1.5rem);
    // color: $blue-light-1-color;
    color: $primary-color;
}
.KpiChartSettings .colorSelector .colorInputDiv .colorDropperInput{
    aspect-ratio: 1;
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

}
.KpiChartSettings .colorSelector .colorInputDiv .colorDropperInput input{
    width: 120%;
    height: 120%;
    padding: 0;
    opacity: 0;
}
.KpiChartSettings .colorSelector .colorInputDiv hr{
    width: 0.1rem;
    height: 1.5rem;
    margin: 0 .6rem;
    background: $blue-light-1-color;
}
.KpiChartSettings .colorSelector .colorInputDiv .colorOpacityInput{
    width: 3rem;
    display: flex;
    align-items: center;
}
.KpiChartSettings .colorSelector .colorInputDiv .colorOpacityInput input{
    width: 2rem;
}
.KpiChartSettings .TheInputDiv{
    margin: .25rem 0;
    display: flex;
    flex-direction: column;
    gap: .25rem;
}
.KpiChartSettings .TheInputDiv input{
    width: 100%;
    outline: none;
    border: none;
    background: $gray-light-3-color;
    // height: 2rem;
    padding: .5rem .75rem;
    border-radius: 6px;
}

.KpiChartSettings .fontStyleDiv{
    display: flex;
    align-items: center;
    gap: .5rem;
    // margin: .5rem 0;
}
.KpiChartSettings .fontStyleDiv .fontStyleSquare{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    aspect-ratio: 1;
    border-radius: 6px;
    background-color: $gray-light-3-color;
    font-size: 1.3rem;
    user-select: none;
    cursor: pointer;
    transition: 300ms ease-in-out;
}
.KpiChartSettings .fontStyleDiv .fontStyleSquare:hover{
    background-color: $gray-light-2-color;

}
.KpiChartSettings .fontStyleDiv .fontStyleSquare.fontStyleItalic{
    font-style: italic;
    font-family: 'Times New Roman';
}
.KpiChartSettings .fontStyleDiv .fontStyleSquare.fontStyleUnderLine{
    text-decoration-line: underline;
}
.KpiChartSettings .fontStyleDiv .fontStyleSquare b{ 
    color: $blue-color;
}
.KpiChartSettings .colorSelector .gradientChangeDiv{
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: flex-end;
    height: 5rem;
    width: 100%;
    border-radius: 6px;
}
.KpiChartSettings .colorSelector .gradientChangeDiv .colorShow{
    position: absolute;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    left: 0;
}
.KpiChartSettings .colorSelector .gradientChangeDiv .changeButton{
    background-color: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.2rem;
    z-index: 1;
    margin: .35rem;
    aspect-ratio: 1;
    border-radius: 5rem;
    user-select: none;
    cursor: pointer; 
    transition: 100ms ease-in-out;
&:hover{
    transform: rotate(-20deg);
}
}
.KpiChartSettings .colorSelector .gradientChangeDiv .changeButton img{
    width: 65%;
}
.KpiChartSettings .colorSelector .opacityRange{
    width: 80%;
    margin-left: 10%;
}
// .KpiChartSettings .mainContainer .secondColumn .scrollableDiv.style .outerDiv .grayContainers .containerItems.horizontalDiv .inputDivContainer {
//     display: flex;
//     gap: 1rem;
// }

.KpiChartSettings .mainContainer .secondColumn .scrollableDiv.style .outerDiv .grayContainers .containerItems  .gridLinesInnerDiv {
    display: grid;
    gap: 1rem;
    grid-template-columns: 5rem 1fr;
}
.KpiChartSettings .mainContainer .secondColumn .scrollableDiv.style .outerDiv .grayContainers .containerItems  .widthAndCountInputContainer {
    display: flex;
    gap: 1rem;
}

.KpiChartSettings .mainContainer .secondColumn .scrollableDiv.style .outerDiv .grayContainers .containerItems .widthInputDiv {
    width: 4rem;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    border-radius: 6px;
    background-color: $gray-light-3-color;
    color: $primary-color;
}
.KpiChartSettings .mainContainer .secondColumn .scrollableDiv.style .outerDiv .grayContainers .containerItems .widthInputDiv input{
    width: 1.9rem;
    background: transparent;
    border: none;
    outline: none;
    color: $primary-color;
    margin-right: .1rem;
}

//Legend

.KpiChartSettings .mainContainer .secondColumn .scrollableDiv.style .outerDiv .grayContainers .containerItems.legend .opacityOuterDiv{
    display: flex;
    justify-content: center;
    padding: .5rem 1rem;
    flex-direction: column;
}

.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .minimizablegrayContainer{
    display: flex;
    flex-direction: column;
    background: $gray-light-1-color;
    border-radius: 6px;
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .minimizablegrayContainer .minimizableItems{
    background-color: $white-color;
    margin: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
    // gap: .5rem;
    min-height: 2.5rem;
    padding: 0.5rem 1rem;
    border-radius: 6px;
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .minimizablegrayContainer .minimizableItems:nth-child(1){
    margin-top: .5rem;
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .minimizablegrayContainer .minimizableItems:nth-last-child(1){
    margin-bottom: .5rem;
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .minimizablegrayContainer .minimizableItems .titleDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .minimizablegrayContainer .minimizableItems .titleDiv.cursorPointer{
    cursor: pointer;
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .containerItems.legend .minimizablegrayContainer .minimizableItems .titleDiv img{
    cursor: pointer;
    user-select: none;
    height: .5rem;
    transition: 200ms ease-in-out;
    transform: rotate(0deg);
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv  .containerItems.legend .minimizablegrayContainer .minimizableItems.open .titleDiv img{
    transform: rotate(180deg);
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .minimizablegrayContainer .minimizableItems .minimizableContentDiv{
    max-height: 0px;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .minimizablegrayContainer .minimizableItems.open .minimizableContentDiv{
    display: block;
    max-height: 999em;
    padding-top: 0.5rem;
    transition: max-height 300ms ease-in-out;
}
.KpiChartSettings .mainContainer .scrollableDiv .outerDiv .minimizablegrayContainer .minimizableItems select{
    background-color: $gray-light-3-color;
    height: 2.5rem;
    border-radius: 6px;
    width: 100%;
    padding: 0.5rem 1rem;
    outline: none;
    color: $primary-color;
    border: none;
}