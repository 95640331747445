$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;


.KpiChartSettings .nonSupported{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    font-size: 1.5rem;
    padding: 0rem;
    z-index: 99999;
    background-color: $white-color;
  }
  .KpiChartSettings .nonSupported .header{
    font-size: 1rem;
    box-sizing: border-box;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    height: 4rem;
    gap: 1rem;
    border-bottom: 1px solid #DFE2EF;
  }
  .KpiChartSettings .nonSupported  .header .title {
    font-weight: 600;
}
  .KpiChartSettings .nonSupported  .header .subTitle {
    font-weight: 500;
    color: $secondary-color;
}


.KpiChartSettings  .nonSupported  .header .closeButton{
  font-weight: 600;
  box-sizing: border-box;
  display: flex;
  background: #7e829999;
  box-shadow: 0px 2px 20px #0000001a;
  justify-content: center;
  color: $white-color;
  align-items: center;
  border-radius: 4rem;
  cursor: pointer;
  transition: 200ms ease-in-out;
  user-select: none;
 
  height: 2rem;
  width: 2rem;
  font-size: 1.2rem;
  &:hover {
    background:$blue-light-1-color;
}
}
.KpiChartSettings .nonSupported .header .goleftSide {
  margin-left: auto;
}


.KpiChartSettings .nonSupported .nonSupportedMessageSection {
    height: calc(100% - 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 4px;
}

  @media (min-width: 900px) {
      .KpiChartSettings .nonSupported{
        display: none;
      }
      
  }