$white-color: white;
$black-color: #242424;

$Light-Blue-1-Color: #c2daff;
$Light-Blue-2-Color: #aec8f0;

$primary-color: #3f4254;
$secondary-color: #7e8299;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$black-hover-color: #00000025;
$red-1-color: #d92232;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;


.ReportSettings .pageInnerContent .customColumnOuterArea{
  display: flex;
  gap: .5rem;
  position: relative;

}
.ReportSettings .pageInnerContent .customColumnOuterArea .sqlMainArea{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
$sqlChatOuterAreaHeight:4rem;

.ReportSettings .pageInnerContent .customColumnOuterArea .sqlMainArea .sqlMainCodingArea{
  display: flex;
  gap: .5rem;
  height: calc(100% - #{$sqlChatOuterAreaHeight} - 1rem);
}
.ReportSettings .tableCustomColumn .loadingContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  background-color: $black-hover-color;
  opacity: 0;
  animation: opacityAnimation 100ms forwards;
}

@keyframes opacityAnimation {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 100;
  }
}

.ReportSettings .tableCustomColumn .PopUpDivChild.sqlAIQuery .PopUpInnerContaier{
  max-width: 80%;
  max-height: 80%;
  height: auto;
  width: auto;
  min-height: auto;
}
.ReportSettings .tableCustomColumn .PopUpDivChild.sqlAIQuery .sqlInnerQueryArea .editorArea .mainEditorArea{
  max-height: 60vh;
  height: auto;
}
.ReportSettings .tableCustomColumn .PopUpDivChild.sqlAIQuery .sqlInnerQueryArea .editorArea .editorTitle{
  display: flex;
  cursor: pointer;
  padding-top: .15rem;
  padding-bottom: .15rem;
  margin-bottom: .2rem;
  border-radius: 6px;
  transition: 200ms ease-in-out;
}
.ReportSettings .tableCustomColumn .PopUpDivChild.sqlAIQuery .sqlInnerQueryArea .editorArea .editorTitle:hover{
  background-color: $secondary-color;
}
.ReportSettings .tableCustomColumn .PopUpDivChild.sqlAIQuery .sqlInnerQueryArea .editorArea .copyQueryToClipBoardIcon{
  margin-left: auto;
  opacity: 0;
}
.ReportSettings .tableCustomColumn .PopUpDivChild.sqlAIQuery .sqlInnerQueryArea .editorArea:hover .copyQueryToClipBoardIcon{
  opacity: 1;
  // background-color: $secondary-color;
  // padding: 0 .25rem ;  
  // border-radius: 6px;
}
.ReportSettings .pageInnerContent .customColumnOuterArea .customColumnsDisplayOffMsg{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  color: $gray-light-1-color;
}
$sqlInstructionWidth:15rem;

.ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionArea{
  width: $sqlInstructionWidth;
}

  .ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionSaprator{
  background: $black-hover-color;
  width: 1px;
  height: 100%;
}
.ReportSettings .pageInnerContent .customColumnOuterArea .customColumnsInnerArea{
  width: calc( 100% - #{$sqlInstructionWidth});
}
.ReportSettings .pageInnerContent .customColumnOuterArea .customColumnsInnerArea{
  height: 100%;
}
.ReportSettings .pageInnerContent .customColumnOuterArea .sqlOuterArea.sqlOuterAreaFullHeight {
  height: 100%;
}
.ReportSettings .pageInnerContent .customColumnOuterArea .sqlOuterArea {
  height: calc(100% - 5rem);
  overflow: auto;
}
.ReportSettings .pageInnerContent .customColumnOuterArea .chatBoxArea{
  height: $sqlChatOuterAreaHeight;
}
.ReportSettings .pageInnerContent .customColumnOuterArea .sqlOuterArea .greyText{
  color: $secondary-color;
   font-size: 1.25rem;
   font-weight: 400;
   word-wrap: break-word;
   text-align: center;
  }
  .ReportSettings .pageInnerContent .customColumnOuterArea .sqlOuterArea .greyText.noWrap{
    overflow: hidden;
    white-space: nowrap;
    font-size: 1rem;
    // font-weight: 200;
  }
.ReportSettings .tableCustomColumn .sqlInnerQueryArea {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

.ReportSettings .tableCustomColumn .sqlInnerQueryArea .sqlStatementTitle {
  color: $secondary-color;
  font-size: 1.2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.ReportSettings .tableCustomColumn .sqlInnerQueryArea .editorArea {
  background-color: $primary-color;
  color: $white-color;
  border-radius: 8px;
  padding: 0.5rem;
  transition: 200ms ease-in-out;
}
.ReportSettings .tableCustomColumn .sqlInnerQueryArea .editorArea.statementEditor {
  height: calc(100% - 12.5rem);
}
.ReportSettings .tableCustomColumn .sqlInnerQueryArea .editorArea.statementEditor .mainEditorArea{
  height: calc(100% - 1.5rem);
  max-height: none;
}
.ReportSettings .tableCustomColumn .sqlInnerQueryArea .editorArea .editorTitle {
  // margin-bottom: 0.25rem;
  font-size: 0.8rem;
  // margin-left: 0.5rem;
  padding: .25rem .5rem;
  padding-top: 0;
}
.ReportSettings .tableCustomColumn .sqlInnerQueryArea .editorArea .mainEditorArea {
  background-color: $black-color;
  color: $white-color;
  border-radius: 6px;
  overflow: auto;
  // height: 15rem;
  min-height: 5rem;
  max-height: 10rem;
  height: 16vh;
  font-size: 0.95rem;
  outline: none;
  transition: 200ms ease-in-out;
}
.ReportSettings .tableCustomColumn .sqlInnerQueryArea .editorArea.outputEditor {
    margin-top: auto;
  // background-color: $red-3-color;
  .mainEditorArea {
    height: auto;
    max-height: 10rem;
  }
  &.outputError {
    // .mainEditorArea {
    //   color: $red-1-color;
    // }
    &.querySame {
      background-color: $red-3-color;
    }
  }
  &.outputSuccess {
    // .mainEditorArea {
    //   color: $green-1-color;
    // }
    &.querySame {
      background-color: $green-2-color;
    }
  }
}


.ReportSettings .tableCustomColumn .sqlInnerQueryArea .editorArea .mainEditorArea textarea {
  outline: none;
}
.ReportSettings .tableCustomColumn .sqlInnerQueryArea .editorArea .mainEditorArea:focus-within {
  box-shadow: 0 0 2px 0 $Light-Blue-2-Color;
}
.ReportSettings .tableCustomColumn .sqlInnerQueryArea .editorButtonArea {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.ReportSettings .tableCustomColumn .ChatInput .ChatInnerInput {
  width: 100%;
  // margin-left: -5%;
  padding: 0 ;
  // margin: .5rem 0;
  gap: .5rem;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 6px;
  background: #f5f8fa9d;
  justify-content: center;
  backdrop-filter: saturate(180%) blur(2px);
}

.ReportSettings .tableCustomColumn .ChatInput .centerOptionFormOption {
  width: calc(100% - 20rem);
  width: 100%;
  padding: 0 0.5rem;
  // margin: 0.5rem 0;
  display: flex;
  align-items: center;
  // background-color: #2b6ff7;
  gap: 0.25rem;
  justify-content: space-between;
  border-radius: 6px;
  border: solid 1px $black-hover-color;
  background: $white-color;
  backdrop-filter: saturate(180%) blur(10px);
}

.ReportSettings .tableCustomColumn .ChatInput .leftOptionFormOption {
  display: flex;
  justify-content: flex-end;
  // padding: 0 0.5rem;
}

.ReportSettings .tableCustomColumn .ChatInput .leftOptionFormOption .openTableButton {
  color: $white-color;
  // border: solid 1px $secondary-color;
  // background-color: $secondary-color;
  background-color: $blue-color;
  transition: 100ms ease-in-out;
  height: 3.5rem;
  width: 3.5rem;
}
.ReportSettings .tableCustomColumn .ChatInput .leftOptionFormOption .openTableButton:hover {
  filter: brightness(0.9);
}
.ReportSettings .tableCustomColumn .ChatInput .leftOptionFormOption .openTableButton.disable {
  background-color: $Light-Blue-1-Color;
  // border: none;
}
// .ReportSettings .tableCustomColumn .ChatInput .leftOptionFormOption .openTableButton::after {
//     position: absolute;
//     width: .8rem;
//     height: .8rem;
//     border-radius: 1rem;
//     bottom: 0;
//     right: 0;
//     content: "";
//     background-color: #09ff00;
// }
@keyframes openTableButton {
  0% {
    background-color: $gray-light-1-color;
  }
  100% {
    background-color: $gray-light-2-color;
  }
}
.ReportSettings .tableCustomColumn .ChatInput .leftOptionFormOption .openTableButton:hover {
  animation: none;
}

.ReportSettings .tableCustomColumn .ChatInput .tableDropDownDiv {
  width: 15rem;
}

.ReportSettings
  .tableCustomColumn
  .ChatInput
  .tableDropDownDiv
  .CustomDropdown.tableDropDown
  .dropDownArray {
  bottom: 3rem;
  width: 17rem;
  margin: 0;
}
.ReportSettings
  .tableCustomColumn
  .ChatInput
  .tableDropDownDiv
  .CustomDropdown.tableDropDown
  .dropDownArray.open {
  max-height: 25rem;
}
.ReportSettings
  .tableCustomColumn
  .ChatInput
  .tableDropDownDiv
  .CustomDropdown.tableDropDown
  .dropDownArray
  .dropDownItems:nth-child(1) {
  border: none;
}
.ReportSettings
  .tableCustomColumn
  .ChatInput
  .tableDropDownDiv
  .CustomDropdown.tableDropDown
  .dropDownArray
  .dropDownItems {
  border-top: solid 1px #00000011;
}
.ReportSettings
  .tableCustomColumn
  .ChatInput
  .tableDropDownDiv
  .CustomDropdown.tableDropDown
  .mainTitle {
  border-radius: 6px 0 0 6px;
  background-color: $white-color;
  border-right: solid 1px $secondary-color;
}
.ReportSettings .tableCustomColumn .ChatInput .chatBotInputDiv {
  width: calc(100% - 17rem);
  width: 100%;
}

.ReportSettings .tableCustomColumn .ChatInput .chatBotInputDiv .chatBotInput {
  border-radius: 0 6px 6px 0;
  border: none;
  outline: none;
  width: 100%;
  padding: 1rem 1rem;
}
.ReportSettings .tableCustomColumn .ChatInput .chatbotSubmitButton {
  color: $blue-color;
}
.ReportSettings .tableCustomColumn .ChatInput .chatbotSubmitButton.disable {
  color: $Light-Blue-1-Color;
}

.ReportSettings .tableCustomColumn .ChatInput .rightOptionFormOption {
  display: flex;
  // justify-content: flex-end;
  // padding: 0 0.5rem;
}

.ReportSettings .tableCustomColumn .ChatInput .rightOptionFormOption .surpriseButton {
  color: $white-color;
  // border: solid 1px $secondary-color;
  background-color: $blue-color;
  height: 3.5rem;
  // width: 3rem;
  border: solid 1px #c8c8c8;
  margin: 0;
  padding: 0;
  transition: 0 ease-in-out;
  border-radius: 6px;
  display: flex;
  align-items: center;

  gap: 0.5rem;
  padding: 0 1rem;
}
.ReportSettings .tableCustomColumn .ChatInput .rightOptionFormOption .surpriseButton:disabled {
  background-color: $Light-Blue-1-Color;
}

.ReportSettings
  .tableCustomColumn
  .ChatInput
  .rightOptionFormOption
  .surpriseButton
  .surpriseMeicon {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}

.ReportSettings
  .tableCustomColumn
  .ChatInput
  .rightOptionFormOption
  .surpriseButton
  .surpriseMeText {
  font-size: 1rem;
  // font-weight: 500;
  display: flex;
  width: 6rem;
  align-items: center;
}
.ReportSettings
  .tableCustomColumn
  .ChatInput
  .rightOptionFormOption
  .surpriseButton
  .loadingSpinner {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
}
.ReportSettings
  .tableCustomColumn
  .ChatInput
  .rightOptionFormOption
  .surpriseButton.submitEnable
  .surpriseMeText {
  width: 3rem;
}
