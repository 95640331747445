$white-color:white;
$Light-Blue-1-Color:#c2daff;
$Light-Blue-2-Color:#aec8f0;


$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;





.DataConnectors .tableOuterContainer {
    background-color: $white-color;
    padding: 1rem;
    box-shadow: 0 0 1px 1px $black-hover-color;
    border-radius: 6px;
    bottom: 6rem;
    position: absolute;
    animation: slideUp 300ms forwards;
    left: 1.5rem;
    max-width: calc(100% - 3rem);
    // right: 0;
}
@keyframes slideUp {
    0%{
        bottom: -10rem;
        opacity: 0;
    }
    100%{
        bottom: 6rem;
        opacity: 1;
    }
}
$tickLength:18px;
$bottomTickLength:9px;

.DataConnectors .tableOuterContainer:after {
    content: '';
    position: absolute;
    font-size: 12px;
    width: 0;
    height: 0;
    border-bottom: $tickLength solid $white-color;
    border-left: $tickLength solid transparent;
    border-right: $tickLength solid transparent;
    left:  calc(-#{$tickLength} + 9px);;
    bottom: calc(-#{$bottomTickLength} + 1px);;
    rotate: 90deg;
    border-radius:   calc(#{$tickLength} - 2px)  calc(#{$tickLength} - 2px)  calc(#{$tickLength} - 2px) 0;
}

.DataConnectors .tableOuterContainer:before {
    z-index: -1;
    content: '';
    font-size: 12px;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: calc(#{$tickLength} + 1px) solid $black-hover-color;
    border-left: calc(#{$tickLength} + 0px) solid transparent;
    border-right: calc(#{$tickLength} + 1px) solid transparent;
    rotate: 90deg;
    left: calc(-#{$tickLength} - 2px + 10px);
    bottom: calc(-#{$bottomTickLength} - 1px);
    border-radius:   calc(#{$tickLength} - 2px)  calc(#{$tickLength} - 2px)  calc(#{$tickLength} - 2px) 0;
}

.DataConnectors .tableOuterContainer .tableHeaderDiv{
    display: flex;
    padding-bottom: 1rem;
}
.DataConnectors .tableOuterContainer .tableHeaderDiv .tableHeaderTitle{
    width: calc( 100%);
    justify-content: center;
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    position: relative;
}
.DataConnectors .tableOuterContainer .tableHeaderDiv .closeDiv{
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
