
$white-color:white;
$Light-Blue-1-Color:#c2daff;
$Light-Blue-2-Color:#aec8f0;


$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;

$yellow-1-color:#fff3cd ;
$yellow-2-color:#ffeeba;

$tickLength:10px;

.ChatBot .userMessages {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}
.ChatBot .userMessages .mainMessage{
    font-size: 1.1rem;
    letter-spacing: .2px;
    position: relative;
    background-color: $Light-Blue-1-Color;
    margin: 1rem 0;
    display: inline-block;
    padding: 0.5rem 1rem;
    align-self: flex-end;
    border: 1px solid $Light-Blue-2-Color;
    border-radius: calc(#{$tickLength} - 2px);
}
.ChatBot .userMessages .logoDiv{
    position: absolute;
    right: 0rem;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    border-radius: 5rem;
    align-items: center;
    border : 2px solid $blue-color;
    font-size: 2rem;
    padding-top: .5rem;
    color: $blue-color;
    overflow: hidden;
}

.ChatBot .userMessages .mainMessage:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: $tickLength solid $Light-Blue-1-Color;
    border-left: $tickLength solid transparent;
    border-right: $tickLength solid transparent;
    bottom: 0;
    right: -$tickLength;
    border-radius: calc(#{$tickLength} - 2px);
}

.ChatBot .userMessages .mainMessage:before {
    z-index: -1;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: calc(#{$tickLength} + 2px) solid $Light-Blue-2-Color;
    border-left: calc(#{$tickLength} + 1px) solid transparent;
    border-right: calc(#{$tickLength} + 1px) solid transparent;
    bottom: -1px;
    right: calc(-#{$tickLength} - 2px);
    border-radius: calc(#{$tickLength} - 2px);
}
.ChatBot .inputField {
    background-color: $white-color;
}
.ChatBot .aiMessages{
    font-size: 1.1rem;
    letter-spacing: .2px;
    position: relative;
    background-color: $white-color;
    margin: 1rem 0;
    display: inline-block;
    padding: 0.5rem 1rem;
    align-self: flex-start;
    border: 1px solid $gray-light-1-color;
    border-radius: calc(#{$tickLength} - 2px);
}
.ChatBot .aiMessages:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: $tickLength solid $white-color;
    border-left: $tickLength solid transparent;
    border-right: $tickLength solid transparent;
    top: 0;
    left: -$tickLength;
    border-radius: calc(#{$tickLength} - 2px);
}

.ChatBot .aiMessages:before {
    z-index: -1;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: calc(#{$tickLength} + 2px) solid $gray-light-1-color;
    border-left: calc(#{$tickLength} + 1px) solid transparent;
    border-right: calc(#{$tickLength} + 1px) solid transparent;
    top: -1px;
    left: calc(-#{$tickLength} - 2px);
    border-radius: calc(#{$tickLength} - 2px);
}
.ChatBot .aiMessages .logoDiv{
    position: absolute;
    top: 0;
    left: -3.75rem;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    border-radius: 5rem;
    align-items: center;
    font-size: 2rem;
    color: $blue-color;
    overflow: hidden;
}
.ChatBot .aiMessages .logoDiv .logo{
    height: 100%;
}
.ChatBot .chartMessage{
    font-size: 1.1rem;
    letter-spacing: .2px;
    position: relative;
    background-color: $white-color;
    margin: 1rem 0;
    display: inline-block;
    padding: 0.5rem 1rem;
    align-self: flex-start;
    border: 1px solid $gray-light-1-color;
    border-radius: calc(#{$tickLength} - 2px);
}
.ChatBot .chartMessage:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: $tickLength solid $white-color;
    border-left: $tickLength solid transparent;
    border-right: $tickLength solid transparent;
    top: 0;
    left: -$tickLength;
    border-radius: calc(#{$tickLength} - 2px);
}

.ChatBot .chartMessage:before {
    z-index: -1;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: calc(#{$tickLength} + 2px) solid $gray-light-1-color;
    border-left: calc(#{$tickLength} + 1px) solid transparent;
    border-right: calc(#{$tickLength} + 1px) solid transparent;
    top: -1px;
    left: calc(-#{$tickLength} - 2px);
    border-radius: calc(#{$tickLength} - 2px);
}
.ChatBot .chartMessage .logoDiv{
    position: absolute;
    top: 0;
    left: -3.75rem;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    border-radius: 5rem;
    align-items: center;
    font-size: 2rem;
    color: $blue-color;
    overflow: hidden;
}
.ChatBot .chartMessage .logoDiv .logo{
    height: 100%;
}

// ErrorMessages

.ChatBot .errorMessage{
    font-size: 1.1rem;
    letter-spacing: .2px;
    position: relative;
    background-color: $yellow-1-color;
    margin: 1rem 0;
    display: inline-block;
    padding: 0.5rem 1rem;
    align-self: flex-start;
    border: 1px solid $yellow-2-color;
    border-radius: calc(#{$tickLength} - 2px);
}


.ChatBot .errorMessage:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: $tickLength solid $yellow-1-color;
    border-left: $tickLength solid transparent;
    border-right: $tickLength solid transparent;
    top: 0;
    left: -$tickLength;
    border-radius: calc(#{$tickLength} - 2px);
}

.ChatBot .errorMessage:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: calc(#{$tickLength} + 2px) solid $yellow-2-color;
    border-left: calc(#{$tickLength} + 1px) solid transparent;
    border-right: calc(#{$tickLength} + 1px) solid transparent;
    top: -1px;
    left: calc(-#{$tickLength} - 2px);
    border-radius: calc(#{$tickLength} - 2px);
}
.ChatBot .errorMessage .logoDiv{
    position: absolute;
    top: 0;
    left: -3.75rem;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    border-radius: 5rem;
    align-items: center;
    font-size: 2rem;
    color: $blue-color;
    overflow: hidden;
}
.ChatBot .errorMessage .logoDiv .logo{
    height: 100%;
}


.ChatBot .errorMessage .messgesDiv{
    display: flex;
}
.ChatBot .errorMessage .messgesDiv .warningIcon{
    width: 2rem;
    font-size: 1.35rem;
    margin-top: -.25rem;
}

.ChatBot .errorMessage .messgesDiv .messages{
    width: calc(100% - 2rem);
}

.ChatBot .errorMessage .openCreatChartButton{
    margin: .5rem 0;
    display: flex;
}
.ChatBot .errorMessage .openCreatChartButton .link{
    text-decoration: underline;
    color: $blue-color;
    cursor: pointer;
}
.ChatBot .errorMessage .openCreatChartButton .link:hover{
    color: #0184cf;
}
.ChatBot .errorMessage .openCreatChartButton .link:active{
    color: #0069a7;
}

//Chart 

.ChatBot .chartMessageContainer .pinningOptionContainer{
    position: absolute;
    width: 20rem;
    right: 1rem;
    top: 2rem;
}

.ChatBot .chartMessageContainer .chartContainer .col-xxl-6{
    width: 100%;

}
.ChatBot .chartMessageContainer {
    display: flex;
    
}
.ChatBot .chartMessageContainer .chartOptions{
    padding: 1rem 1rem;
}
.ChatBot .chartMessageContainer .chartOptions .card-toolbar{
    position: relative;    
    }
.ChatBot .chartMessageContainer .chartOptions .card-toolbar .btn-color-primary:hover{
    background-color: #dfeff7;
    }
.ChatBot .chartMessageContainer .chartOptions .card-toolbar .timframeDropdown.CustomDropdown .dropDownArray{
    width: 11rem;
    right: 3rem;
    top: 2rem;
    }
.ChatBot .chartMessageContainer .chartOptions .card-toolbar .timframeDropdown.CustomDropdown .mainTitle{
   padding: 0;
   margin: 0;
   width: 3rem;
    }