$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;



.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTable{
  height: 100%;
  width: 100%;
  border: 1px solid $blue-light-1-color;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableTitleContainer{
  height: 4rem;
  padding: .5rem;
  width: 100%;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableTitleContainer .titleDiv{
  font-size: 1.4rem;
  margin-left: .5rem;
}

.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer{
  height:calc( 100% - 4rem);
  width: 100%;
  // background-color: $gray-light-1-color;
  padding: .5rem;
  border-radius: 6px;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .kpiChartCircleWiseTargetPointTableInnerContainer{
  height:100%;
  width: 100%;
  // background-color: $gray-light-1-color;
  padding: .5rem;
  border-radius: 6px;
  border: 1px solid $blue-light-1-color;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .circleWiseTargetPointTableHeader{
  height: 3.5rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  // font-size: 1.25rem;
  font-weight: 500;
  width: 100%;
  border-bottom: 1px solid $gray-light-1-color;
}

.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .circleWiseTargetPointTableElemContainer{
  height: calc( 100% - 3.5rem);
  padding-top: .5rem;
  overflow: auto;
  width: 100%;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .selectCellDiv{
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  input{
  cursor: pointer;
  }
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .circleCellDiv{
  width: calc(50% - 5.5rem);
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .targetPoinCellDiv{
  width: calc(50% - 5.5rem);
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .targetPoinCellDiv .targetPoinCellInputContainer{
  width: 100%;
  display: flex;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .targetPoinCellDiv .targetPoinCellInputContainer .targetPoinCellInput.targetPoinCellInputError{
  box-shadow: inset 0 0 0 1px $red-1-color;
}
  .KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .targetPoinCellDiv .targetPoinCellInputContainer .targetPoinCellInput{
  width: calc(100% - 2rem);
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .targetPoinCellDiv .targetPoinCellInputContainer .targetPoinCellInputRemove{
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .filterCellDiv{
  width: 5rem;
  position: relative;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .filterCellDiv .filterButtonNumber{
  background: $blue-color;
  border: 1px solid $blue-light-2-color;
  color: $white-color;
  position: absolute;
  right: -.5rem;
  font-size: .7rem;
  top: -.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .removeCellDiv{
  width: 2rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .removeCellDiv.rightCellDiv{
  color: $blue-color;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .removeCellDiv.disableAddButton{
  color: $gray-light-1-color;
  cursor: auto;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .circleWiseTargetPointTableElem{
  // height: 3.5rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  width: 100%;
  // border-bottom: 1px solid $gray-light-1-color;
}
.KpiChartSettings .mainContainer .thirdColumn .kpiChartCircleWiseTargetPointTableContainer .circleWiseTargetPointTableElem.addCircleWiseTargetPoint{
  border-radius: 6px;
  border: 1px solid $gray-light-1-color;
}