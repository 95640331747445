$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;




.DataConnectors .editTablePopup .editTableOuterContainer{
  height: 100%;
  width: 100%;
  position: relative;
}
.DataConnectors .editTablePopup .editTableOuterContainer .tableDisplayPopup .PopUpInnerContaier{
  max-width: 80%;
  min-height: 50%;
  max-height: 80%;
  height: auto;
  width: auto;
}
.DataConnectors .editTablePopup .editTableOuterContainer .ConfirmationDiv .PopUpInnerContaier{
  max-width: 80%;
  min-width: auto;
  min-height: 10%;
  max-height: 80%;
  height: auto;
  width: auto;
}
.DataConnectors .editTablePopup .PopUpInnerContaier{
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
.DataConnectors .editTablePopup .PopUpInnerContaier .ChildernContainer{
  height: 100%;
  width: 100%;
}

.DataConnectors .editTablePopup .editTableOuterContainer .editTableInnerContainer{
  display: flex;
  height: 100%;
  width: 100%;
}
$editTableListWidth:30rem;

.DataConnectors .editTablePopup .editTableList{
  height: 100%;
  width: $editTableListWidth;
}
.DataConnectors .editTablePopup .tableCustomColumn{
  height: 100%;
  width: calc(100% - #{$editTableListWidth});
}


.DataConnectors .editTablePopup .editTableOuterContainer .editTableHeader{
  // width: 100%;
  // display: flex;
  // justify-content: flex-end;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}
.DataConnectors .editTablePopup .editTableOuterContainer .editTableHeader .crossButton{
  background-color: $gray-light-2-color;
  color: $secondary-color;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  transition: 150ms ease-in-out;
}
.DataConnectors .editTablePopup .editTableOuterContainer .editTableHeader .crossButton:hover{
  background-color: $blue-color;
  color: $white-color;
}
.DataConnectors .editTablePopup .editTableOuterContainer .editTableHeader .editTableListTitle{
  width: $editTableListWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
}
$editTableTitleHeight:5rem;
$editTableNameHeight:7.5rem;

.DataConnectors .editTablePopup .editTableList .editTableTitle{
  height: $editTableTitleHeight;
}
.DataConnectors .editTablePopup .editTableOuterContainer .editTableTitle{
  height: $editTableTitleHeight;
}
.DataConnectors .editTablePopup .edittableName{
  height: calc(#{$editTableNameHeight} );
  padding: 0 1rem;
  position: relative;
  z-index: 2;
  // margin-bottom: .5rem;
}
.DataConnectors .editTablePopup .editTableOptions{
  padding: 0 1rem;
  height: calc(100% - 5rem - #{$editTableTitleHeight} - #{$editTableNameHeight} );
  
  overflow: hidden auto ;
}
.DataConnectors .editTablePopup .editTableButtons{
  padding: 1.5rem 1rem;
  position: sticky;
  bottom: -2px;
  padding-top: 1rem;
}
.DataConnectors .editTablePopup .customColumnOuterArea{
  height: calc(100% - 1rem - #{$editTableTitleHeight} );
  margin: 1rem;
  margin-bottom: 0;
  padding: 1rem;
  padding-bottom: 0;
  background: #F5F8FA; 
  border-radius: 8px; 
  border: 1px #B4C9D8 dashed;
}
$sqlOuterAreaHeight:5rem;

.DataConnectors .editTablePopup .customColumnOuterArea .sqlOuterArea{
  height: calc(100% - #{$sqlOuterAreaHeight});
  overflow: auto;
}
.DataConnectors .editTablePopup .customColumnOuterArea .sqlOuterArea.sqlOuterAreaFullHeight{
  height: 100%;
}
.DataConnectors .editTablePopup .customColumnOuterArea .sqlOuterArea .autoGeneratedColumnsOuter{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.DataConnectors .editTablePopup .customColumnOuterArea .sqlOuterArea .autoGeneratedColumnsOuter .autoGeneratedColumnsInner{
  padding: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.DataConnectors .editTablePopup .customColumnOuterArea .chatBoxArea{
  height: $sqlOuterAreaHeight;
}