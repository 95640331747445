$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$gray-light-4-color: #EEF0F8;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;



.dashboardWrapper .dashboardWrapperSection{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections{
    min-height: 25rem;
    // background-color: aqua;
    position: relative;

    border: dashed 1px $primary-color;
    border-radius: 6px;
    padding: 0.5rem;
    // padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .sectionName{
    position: absolute;
    top: -.75rem;
    left: 2rem;
    padding: 0 .25rem;
    background-color: $white-color;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .headerOuterDiv .headerDiv{
    display: flex;
    gap: .75rem;
    align-items: center;
    padding: 0 .5rem;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .headerOuterDiv .headerDiv .addHeader{
    color: $white-color;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: 1.2rem;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .headerOuterDiv .headerDiv  .headerInput{
    width: calc(100% - 13rem);
}

.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .headerOuterDiv .headerDiv .headerStyle{
    position: relative;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .headerOuterDiv .headerDiv .headerStyle .primaryButton{
    display: flex;
    align-items: center;
    height: auto;
    cursor: pointer;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .headerOuterDiv .headerDiv .headerStyle .editHeaderStyleOuterDiv{
    position: absolute;
    right: 0rem;
    top: 3rem;
    width: 16rem;
    border-radius: 6px;
    overflow: hidden;
    // overflow-y: scroll;
    z-index: 5;
    background-color: $white-color;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    padding: 0;
    max-height: 0;
    transition: 300ms ease-in-out;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .headerOuterDiv .headerDiv .headerStyle .editHeaderStyleOuterDiv.open{
    padding: 1rem;
    max-height: 27rem;
    overflow-y: scroll;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionMainContainer {
    position: relative;
    background-color: $gray-light-4-color;
    border-radius: 6px;
    min-height: 20rem;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionMainContainer .blankSection{
    display: flex;
    justify-content: center;
    height: 20rem;
    position: absolute;
    left: 0;
    right: 0;
    align-items: center;

}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionMainContainer .blankSection .blankSectionInner{
    border: dashed 1px $primary-color;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    align-items: center;
    letter-spacing: 1px;
    font-size: 1.5rem;
    height: 10rem;
    padding: 0 2rem;
}

.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer {
    min-height: 20rem;
    // display: flex;
    // flex-direction: column;
    transition: 200ms ease-in-out;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: .5rem 1rem ;
    gap: 1rem;
}

.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer:nth-child(1){
    padding-top: 1rem;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer:nth-last-child(1){
    padding-bottom: 1rem;
    // min-height: auto;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer.addChartInBottom{
    padding-bottom: 1rem;
    min-height: 10rem;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts{
    width: 100%;
    background-color: white;
    border-radius: 6px;
    padding: 1rem;
    height: 20rem;
    grid-column: auto / span 2;
    
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .ChartType{
    // display: flex;
    align-items: center;
    gap: .5rem;

}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .ChartType .chartIcon{
    height: 5rem;
    aspect-ratio: 1;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .ChartType .chartTypeName{
    font-size: 1.25rem;
    // font-weight: 500;
    text-align: center;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .chartName{
    font-size: 1.25rem;
    font-weight: 500;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;

}

.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts.FULL{
    grid-column: auto / span 8;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts.HALF{
    grid-column: auto / span 4;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts.TWOTHIRD{
    grid-column: auto / span 6;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts.ONEFOURTH{
    grid-column: auto / span 2;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .removeSectionCharts{
    height: 1.75rem;
    width: 1.75rem;
    cursor: pointer;
    // background-color: #00A3FF;
    position: absolute;
    top: .5rem;
    right: .5rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: 100ms ease-in-out;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .removeSectionCharts img{
    height: 70%;
    aspect-ratio: 1;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .selectChartWidth{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    // background-color: #00A3FF;
    position: absolute;
    top: .5rem;
    left: .85rem;
    border-radius: 2rem;
    display: grid;
    place-items: center;
    transition: 100ms ease-in-out;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .selectChartWidth img{
    height: 50%;
    margin: 0;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .selectChartWidth .chartWidthDropDown.CustomDropdown .mainTitle{
    background-color: $white-color;
    margin: 0;
    height: 2rem;
    padding: 0;
}
.dashboardWrapper .dashboardWrapperSection .dashboardInnerSections .dashboardDraggableSectionContainer .sectionCharts .selectChartWidth .chartWidthDropDown.CustomDropdown .dropDownArray{
    width: 10rem;
}