$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;

.PinningOptions{
    position: relative;
    width: 100%;
}
.PinningOptions .mainTitle{
    background-color: $gray-light-3-color;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    width: 100%;
    padding: 0.5rem 1rem;
    outline: none;
    color: $primary-color;
    border: none;
    margin: .5rem 0;
    cursor: pointer;
    user-select: none;
}
.PinningOptions .mainTitle .dropDownValue{
    width: calc( 100%  - .75rem);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.PinningOptions .mainTitle input{
    width: calc( 100%  - .75rem);
    // width: 100%;
    // padding: 0.5rem 0.75rem;
    margin-right: .75rem;
    align-items: center;
    background: $gray-light-3-color;
    outline: none;
    border: none;
    border-radius: 6px;
} 
.PinningOptions .mainTitle .dropDownIcon{
    font-size: 1rem;
    transition: 200ms ease-in-out;
    margin-left: auto;
}
.PinningOptions .mainTitle .dropDownIcon.open{
    transform: rotate(180deg);
}
.PinningOptions .mainTitle img{
    height: 30%;
    transition: 200ms ease-in-out;
    margin-left: auto;
}
.PinningOptions .mainTitle img.open{
    transform: rotate(180deg);
}
.PinningOptions .fullDiv {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    // background-color: #00A3FF;
}
.PinningOptions .dropDownArray {
    z-index: 5;
    max-height: 0px;
    position: absolute;
    width: 95%;
    margin: 0 2.5%;
    background-color: $gray-light-2-color;
    box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    border-radius: 6px;
    flex-direction: column;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
    font-size: .9rem;
}
.PinningOptions .dropDownArray.open {
    min-height: 3rem;
    max-height: 15em;
    overflow: auto;
    transition: max-height 300ms ease-in-out;
}

.PinningOptions .dropDownArray .dropDownItems{
    padding: .25rem 1rem;
    cursor: pointer;
    background-color: $white-color;
    border-top: solid 1px #00000011;
    transition: 300ms ease-in-out;
}
.PinningOptions .dropDownArray .dropDownItems.selected{
    background-color: $gray-light-1-color;

}
.PinningOptions .dropDownArray .dropDownItems:hover{
    background-color: $gray-light-2-color;
}
.PinningOptions .dropDownArray .dropDownItems:nth-child(1){
    padding-top: .5rem;
}
.PinningOptions .dropDownArray .dropDownItems:nth-last-child(1){
    padding-bottom: .5rem;
}


.PinningOptions .dropDownArray .dropDownItems.dashboard{
    border: none;
}
.PinningOptions .dropDownArray .dropDownItems.dashboard .createNewDashboardTitle{
    transition: 300ms ease-in-out;
    color: $blue-color;
}
.PinningOptions .dropDownArray .dropDownItems.dashboard.open .createNewDashboardTitle{
    font-size: .8rem;
}
.PinningOptions .dropDownArray .dropDownItems.dashboard .creactNewDashboardContainer{
padding-bottom: .25rem;
}