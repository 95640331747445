.OrdinalLegend{
    position: relative
}
.OrdinalLegend .legendShowMoreDiv{
    position: absolute;
    background-color: white;
    margin-left: 25px;
    overflow-y: auto;
    padding: 0px 2rem 2rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(33, 33, 33, 0.100) 0px 1px 2px 1px;
    opacity: 0;
    bottom: 0rem;
    transition: 300ms ease-in-out;
    z-index: 10;
}
.OrdinalLegend .legendShowMoreDiv.show{
    animation: showMoreDivAnimation 200ms  ease-in-out forwards;
}
@keyframes showMoreDivAnimation {
    0%{
        opacity: 0;
        bottom: -3rem;
    }
    100%{
        opacity: 1;
        bottom: 0rem;
    }
}