$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$gray-light-4-color: #EEF0F8;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;




.ReportSettings .selectReportType {
  display: flex;
  justify-content: center;
}
.ReportSettings .selectReportType .innerSelectReportType {
  display: grid;
  gap: 1rem;
  padding: 1rem;
  width: clamp(10rem, 100%, 40rem);
}
.ReportSettings .selectReportType .innerSelectReportType .selectReportTypeLabel{
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: .5rem;
}
  .ReportSettings .selectReportType .reportNameDiv {
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  place-items: center;
}

.ReportSettings .selectReportType .reportNameDiv .titleDiv{
  font-weight: 500;
}

.ReportSettings .selectReportType .reportNameDiv input{
  width: 100%;
  padding: 0.5rem 0.75rem;
  background: transparent;
  background: $gray-light-3-color;
  outline: none;
  border: none;
  border-radius: 6px;
}
.ReportSettings .selectReportType .reportDescriptionDiv {
  display: grid;
  gap: 1rem;
}

.ReportSettings .selectReportType .reportDescriptionDiv .titleDiv{
  font-weight: 500;
}
.ReportSettings .selectReportType .reportDescriptionDiv textarea{
  width: 100%;
  padding: 0.5rem 0.75rem;
  background: transparent;
  background: $gray-light-3-color;
  outline: none;
  border: none;
  border-radius: 6px;
  resize: none;
}
.ReportSettings .selectReportType .reportSelectionDiv {
  display: grid;
  gap: 1rem;
}
.ReportSettings .selectReportType .reportSelectionDiv .titleDiv{
  font-weight: 500;
}
.ReportSettings .selectReportType .reportSelectionDiv .subTitleDiv{
  font-weight: 500;
  font-size: .8rem;
  color: $secondary-color;
}
.ReportSettings .selectReportType .reportSelectionDiv .reportSelectionLoweDiv{
  display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.ReportSettings .selectReportType .reportSelectionDiv .reportSelectionItem{
  display: grid;
  grid-template-columns: 1rem 1fr;
  gap: 1rem;
  // padding: 1rem;
  padding: .5rem;
  cursor: pointer;
}
.ReportSettings .selectReportType .reportSelectionDiv .reportSelectionItem .reportSelectionItemRadioButton{
  display: grid;
  place-items: center;
}
.ReportSettings .selectReportType .reportSelectionDiv .reportSelectionItem .reportSelectionItemTitle{
 font-weight: 500;
 color: $secondary-color;
}