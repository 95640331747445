$white-color: white;
$black-color: #242424;

$Light-Blue-1-Color: #c2daff;
$Light-Blue-2-Color: #aec8f0;

$primary-color: #3f4254;
$secondary-color: #7e8299;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$black-hover-color: #00000025;
$red-1-color: #d92232;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;


.ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionArea .sqlInstructionlist{
  background: $gray-light-1-color;
    border-radius: 6px;
    padding: 0.25rem 0.5rem ;
    display: flex;
    min-height: 3rem;
    max-height: calc(100% - 2rem);
    overflow: auto;
    flex-direction: column;
}
.ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionArea .sqlInstructionlist .sqlInstructionElem{
  background-color: $white-color;
  font-size: .85rem;
  margin: 0.25rem 0;
  padding: 0.5rem .5rem;
  padding-right: 1rem;
  border-radius: 6px;
  transition: 100ms ease-in-out;
  
}
.ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionArea .sqlInstructionlist .sqlInstructionElem.sqlInstructionElemWithCopy{
  cursor: pointer;
  position: relative;
}
.ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionArea .sqlInstructionlist .sqlInstructionElem.sqlInstructionElemWithCopy .sqlInstructionCopyToClipBoardIcon{
  position: absolute;
  // left: 0;
  top: .5rem;
  right: .25rem;
  // bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  transition: 200ms ease-in-out;
  opacity: 0;
  font-size: .25rem;

}
.ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionArea .sqlInstructionlist .sqlInstructionElem.sqlInstructionElemWithCopy:hover {
  background-color: $gray-light-3-color;
  .sqlInstructionCopyToClipBoardIcon{
    opacity: 1;
    font-size: .85rem;
  }
}


.ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionArea .sqlInstructionlist .sqlInstructionElemContainer{
  background-color: $white-color;
  font-size: .85rem;
  margin: 0.25rem 0;
  padding: 0.5rem .5rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  height: 100%;
}

.ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionArea .sqlInstructionlist .InstructionTitle{
  font-size: .9rem;
}
.ReportSettings .pageInnerContent .customColumnOuterArea .sqlInstructionArea .sqlInstructionlist .sqlInstructionElemInnerOptions{
  background: $gray-light-1-color;
  border-radius: 6px;
  padding: 0.25rem 0.5rem ;
  display: flex;
  min-height: 3rem;
  flex-direction: column;
  // max-height: 30vh;
  height: 100%;
  overflow-wrap: break-word;
  overflow: auto;
}
