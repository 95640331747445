$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;

$toggleColumnwidth : 3rem;
$headerHeight : 4rem;
.chartPopUp .mainContainer .toggleColumn{
    display: flex;
    width: 0;
    background-color: $blue-light-1-color;
    position: relative;
    animation: toggleColumn 300ms forwards ease-in-out ;
}
@keyframes toggleColumn {
    0%{
        width: 0;
    }
    100%{
        width: $toggleColumnwidth;
    }
}
.chartPopUp .mainContainer .toggleColumn.close{
    animation: toggleColumnClose 300ms forwards ease-in-out ;
}
@keyframes toggleColumnClose {
    0%{
        width: $toggleColumnwidth;
    }
    100%{
        width: 0;
    }
}
.chartPopUp .mainContainer .toggleColumn .toogleRotatedDiv{
    display: flex;
    position: absolute;
    padding: 0.5rem;
    align-items: center;
    // justify-content: center;
    height: $toggleColumnwidth;
    width: calc( 100vh - #{$headerHeight} );
    gap: 0.5rem;
    transform: rotate(90deg) translate(0rem, 0);
    transform-origin: left top;
    animation: toogleRotatedDiv 300ms forwards ease-in-out ;
}

@keyframes toogleRotatedDiv {
    0%{
        transform: rotate(90deg) translate(0rem, 0);

    }
    100%{
        transform: rotate(90deg) translate(0rem, - $toggleColumnwidth);
    }
}
.chartPopUp .mainContainer .toggleColumn.close .toogleRotatedDiv{
    animation: toogleRotatedDivClose 300ms forwards ease-in-out ;
}

@keyframes toogleRotatedDivClose {
    0%{
        transform: rotate(90deg) translate(0rem, - $toggleColumnwidth);

    }
    100%{
        transform: rotate(90deg) translate(0rem, 0);
    }
}
.chartPopUp .mainContainer .toggleColumnButton{
    font-weight: 500;
    display: flex;
    font-size: 1.2rem;
    padding: 0.2rem 1rem 0.25rem 1rem;
    outline: none;
    border: none;
    border-radius: 6px;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%);
    background-color: $white-color;
    white-space: nowrap;
    transition: 200ms ease-in-out;
}
.chartPopUp .mainContainer .toggleColumnButton:hover{
    background-color: $blue-color;
    color: $white-color;

}
.chartPopUp .mainContainer .toggleColumnButton span{
    transform: rotate(180deg);
}