.ExportDivWrapper{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 800px;
  height: 800px;
  opacity: 0;
}
.ExportDivWrapper.CARDExportWrapper{
  width: 400px;
  height: 800px;
}
.LableExportText{
  opacity: 0;
}
.ExportDivWrapper .LableExportText{
  opacity: 1;
}
.ExportDivWrapper .LableNormalText{
  opacity: 0;
}