
$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;



.OrganizationStructure{
  height: calc(111vh - 15rem );
  background: $white-color;
  padding: 1rem;
}


.OrganizationStructure .primaryInput{
  width: 100%;
  padding:  .5rem .75rem;
  background: transparent;
  background: $gray-light-3-color;
  outline: none;
  border: none;
  border-radius: 6px;
  margin: 0.5rem 0;
}

// Buttons
.OrganizationStructure .primaryButton{
  border: none;
  outline: none;
  background: $blue-color;
  color: $white-color;
  font-weight: 600;
  font-size: .9rem;
  border-radius: 6px;
  height: 3rem;
  padding: 0.5rem 1rem;

  transition: all 200ms ease-in-out;
}
.OrganizationStructure .secondaryButtonColor{
  background: $blue-light-2-color;
  color: $blue-color;
}
.OrganizationStructure .secondaryButtonColor:hover{
  color: $white-color;
}
.OrganizationStructure .primaryButton.secondaryButton:disabled,
.OrganizationStructure .primaryButton:disabled{
  background: $secondary-color;
}
.OrganizationStructure .primaryButton:disabled:hover{
  background: $secondary-color;
}

.OrganizationStructure .primaryButton:hover{
  background: #0091e4;

}
.OrganizationStructure .primaryButton:active{
  background: #007cc4;

}

// removeButton
.OrganizationStructure .primaryButton.removeButton , .OrganizationStructure .primaryButtonSmall.removeButton{
  background: $red-1-color;
  background: $secondary-color;
}
.OrganizationStructure .primaryButton.removeButton:hover , .OrganizationStructure .primaryButtonSmall.removeButton:hover{
  background: $red-2-color;
}
.OrganizationStructure .primaryButton.removeButton:active, .OrganizationStructure .primaryButtonSmall.removeButton:active{
  background: $red-3-color;
}


// $borderColor:$blue-light-1-color;
$borderColor:$gray-light-2-color;
.OrganizationStructure .OrganizationStructureToggleMenu{
  display: flex;
  background-color: $gray-light-3-color;
  width: max-content;
  border-radius: 6px;
  flex-wrap: wrap;
  max-width: 100%;
 }
 .OrganizationStructure .OrganizationStructureToggleMenu .timeframeRangeOption{
   padding:  1rem 2rem;
   font-weight: 500;
   cursor: pointer;

 }
 .OrganizationStructure .OrganizationStructureToggleMenu .timeframeRangeOption.activeTimeframeRangeOption{
   background-color: $blue-color;
   border-radius: 6px;
   color: $white-color;
 }
 .OrganizationStructure .OrganizationStructureContainer{
  height: calc(100% - 3rem);
}
.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer {
  height: 100%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .headerSelectedOrganisationOptionDiv{
  height: 3rem;
  display: flex;
  justify-content: space-between;
  gap: .5rem;
  flex-wrap: wrap;
}
.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .headerSelectedOrganisationOptionDiv .headerSelectedOrganisationTitle{
  font-size: 1.25rem;
  font-weight: 600;
}
.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .headerSelectedOrganisationOptionDiv .headerSelectedOrganisationDescription{
  font-size: 1rem;
  font-weight: 500;
  color: $secondary-color;
}
.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .organisationOptionsHorizontalLine{
  width: calc(100% + 4rem);
  margin-left: -2rem;
}
  .OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .CustomMultiselect .optionsContainer.open{
  max-height: 10rem;
}
  .OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .selectedOrganistionOptionTableConitaner{
  display: grid;
  height: calc(100% - 4rem);   
  align-content: baseline;
  // border: 1px solid $borderColor;
  border-radius: 6px;
  overflow: auto;
} 

 .OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .selectedOrganistionOptionTableConitaner .selectedOrganistionOptionCell{
  padding: 1rem;
  // min-height: 4rem;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $borderColor;
  // border-right: 1px solid $borderColor;
  min-width: 4rem;
  // &:nth-last-child(1){
  // border-right: 0;
  // }
} 

.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .selectedOrganistionOptionTableConitaner .selectedOrganistionOptionCell.selectedOrganistionOptionCellTitle{
  background-color: $gray-light-3-color;
  color: $secondary-color;
  // border-bottom: none;
  font-weight: 600;
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 5;
  &:nth-child(1){
    border-radius: 0 0 0 6px ;
    overflow: hidden;
  }
 }
.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .selectedOrganistionOptionTableConitaner .selectedOrganistionOptionCell.optionCell{
  // background-color: $gray-light-3-color;
  min-width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0;

  
    .mainTitle{
    padding: 0;
    margin: 0;
    width: auto;
  }
  .mainTitle{
    background-color: $white-color;
  }
  .dropDownArray{
    width: 10rem;
    right: 10px;
    top: 40px;
  }
  
 }
 .OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .selectedOrganistionOptionTableConitaner .selectedOrganistionOptionCell.mutiSelectCircleContainer{
   gap: .5rem;
 }
 .OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .selectedOrganistionOptionTableConitaner .selectedOrganistionOptionCell .mutiSelectCircle{
    padding: .5rem 1rem ;
    border-radius: 3rem;
    // background-color: $blue-color;
    // color: $white-color;

    color:  $blue-color;
    background-color: $white-color;
    border: 1px solid  $blue-color;
    font-weight: 600;
 }
.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .editSelectedOrganistainElemForm{
  display: flex;
  flex-direction: column;
  gap: .25rem;
  padding: 0 1rem;
}
.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .editSelectedOrganistainElemForm .titileDiv{
  font-weight: 500;
  margin-left: .25rem;
}

.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .editSelectedOrganistainElemForm .primaryInput{
  margin-top: 0;
}
.OrganizationStructure .OrganizationStructureContainer .organisationOptionsContainer .editSelectedOrganistainElemForm .primaryButtonContainer{
  margin-top: .5rem;
}