$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;


.DataConnectors .editConnectionsPopup .editConnectionForm{
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .fieldAreaOuterContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: calc(100% - 3.5rem);
  max-height: calc(100% - 1.5rem);
  overflow: auto;    
  padding: 0 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .editConnectionFormButtonsContainer{
  display: flex;
  // justify-content: flex-end;
  height: 3rem;
  padding: 0 2rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions{
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions.connectionFormOptionsInRow{
  flex-direction: row;
    align-items: center;
}
// .DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions.connectionFormOptionsInRow{
//   gap: 1rem;
//   align-items: center;
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   padding: .5rem 1rem;
// }
