$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;



.ReportSettings .pageTableSelection {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: .5rem;
  overflow: auto;
  height: 100%;
}
.ReportSettings .pageTableSelection .titleWarningDiv{
  font-size: 0.7rem;
  color: $red-1-color;
}
.ReportSettings .pageTableSelection .primaryInput.errorInput{
  box-shadow: inset 0 0 1px 1px $red-1-color;
}
.ReportSettings .pageTableSelection .openTableButton{
  color: $white-color;
  background-color: $blue-color;
  transition: 100ms ease-in-out;
}
.ReportSettings .pageTableSelection .openTableButton:hover{
  background-color: $blue-color;
  filter: brightness(0.9);
}