
$white-color:white;
$Light-Blue-1-Color:#c2daff;
$Light-Blue-2-Color:#aec8f0;


$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;


.ChatBot .ChatInput {
    position: fixed;
    bottom: 0px;
}
.ChatBot .ChatInput .ChatInnerInput{
    position: relative;
    // width: 110%;
    // margin-left: -5%;
    padding: 0 .5rem;
    // margin: .5rem 0;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background: #f5f8fa9d;
    justify-content: center;
    backdrop-filter: saturate(180%) blur(2px);
}

.ChatBot .ChatInput .leftOptionFormOption,.ChatBot .ChatInput .rightOptionFormOption{
    // width: 10rem;
}
.ChatBot .ChatInput .centerOptionFormOption{
    // width: calc(100% - 20rem);
    width: 100%;
    padding: 0 .5rem;
    margin: .5rem 0;
    display: flex;
    align-items: center;
    // background-color: #2b6ff7;
    gap: .25rem;
    justify-content: space-between;
    border-radius: 6px;
    border: solid 1px $black-hover-color;
    background: $white-color;
    backdrop-filter: saturate(180%) blur(10px);
}


.ChatBot .ChatInput .leftOptionFormOption{
    display: flex;
    justify-content: flex-end;
    padding: 0 .5rem;
}

.ChatBot .ChatInput .leftOptionFormOption .openTableButton {
    color: $white-color;
    // border: solid 1px $secondary-color;
    // background-color: $secondary-color;
    background-color: $blue-color;
    transition: 100ms ease-in-out;
    height: 3.5rem;
    width: 3.5rem;
}
.ChatBot .ChatInput .leftOptionFormOption .openTableButton:hover {
    filter: brightness(0.9);
}
.ChatBot .ChatInput .leftOptionFormOption .openTableButton.disable {
    background-color: $Light-Blue-1-Color;
    // border: none;
}
// .ChatBot .ChatInput .leftOptionFormOption .openTableButton::after {
//     position: absolute;
//     width: .8rem;
//     height: .8rem;
//     border-radius: 1rem;
//     bottom: 0;
//     right: 0;
//     content: "";
//     background-color: #09ff00;
// }
@keyframes openTableButton {
    0%{
    background-color: $gray-light-1-color;

    }
    100%{
        background-color: $gray-light-2-color;
    }
}
.ChatBot .ChatInput .leftOptionFormOption .openTableButton:hover {
    animation: none;
}


.ChatBot .ChatInput .tableDropDownDiv{
    width: 15rem;
}

.ChatBot .ChatInput .tableDropDownDiv .CustomDropdown.tableDropDown .dropDownArray{
    bottom: 4rem;
    width: 17rem;
    margin: 0;
}
.ChatBot .ChatInput .tableDropDownDiv .CustomDropdown.tableDropDown .dropDownArray.open{
    max-height: 50vh;
}

.ChatBot .ChatInput .tableDropDownDiv .CustomDropdown.tableDropDown .mainTitle{
    border-radius: 6px 0 0 6px;
    background-color: $white-color;
    border-right: solid 1px $secondary-color;
}
.ChatBot .ChatInput .tableDropDownDiv .CustomDropdown.tableDropDown .mainTitle.searchDiv input.searchInput{
    background-color: $white-color;
}
.ChatBot .ChatInput .chatBotInputDiv{
    width: calc(100% - 17rem);
    width: 100%;
}

.ChatBot .ChatInput .chatBotInputDiv .chatBotInput{
    border-radius:  0 6px 6px 0;
    border: none;
    outline: none;
    width: 100%;
    padding: 0.5rem 1rem;
}
.ChatBot .ChatInput .chatbotSubmitButton{
    color: $blue-color;
}
.ChatBot .ChatInput .chatbotSubmitButton.disable{
    color: $Light-Blue-1-Color;
}

.ChatBot .ChatInput .rightOptionFormOption{
    display: flex;
    // justify-content: flex-end;
    padding: 0 .5rem;
}

.ChatBot .ChatInput .rightOptionFormOption .surpriseButton {
    color: $white-color;
    // border: solid 1px $secondary-color;
    background-color: $blue-color;
    height: 3.5rem;
    // width: 3rem;
    border: solid 1px #c8c8c8;
    margin: 0;
    padding: 0;
    transition: 0 ease-in-out;
    border-radius: 6px;
    display: flex;  
    align-items: center;

    gap: .5rem;
    padding: 0 1rem;
}
.ChatBot .ChatInput .rightOptionFormOption .surpriseButton:disabled {
    background-color: $Light-Blue-1-Color;
}

.ChatBot .ChatInput .rightOptionFormOption .surpriseButton .surpriseMeicon{
    font-size: 1.25rem;
    display: flex;
    align-items: center;
}
.ChatBot .ChatInput .rightOptionFormOption .surpriseButton .surpriseMeText{
    font-size: 1rem;
    // font-weight: 500;
    display: flex;
    width: 6rem;
    align-items: center;
}
.ChatBot .ChatInput .rightOptionFormOption .surpriseButton.submitEnable .surpriseMeText{
    width: 3rem;
}
.ChatBot .ChatInput .rightOptionFormOption .surpriseButton:hover {
    // color: $white-color;
    // background-color: $gray-light-2-color;
    filter: brightness(0.9);
}
.ChatBot .ChatInput .rightOptionFormOption .surpriseButton img{
    height: 70%;
    aspect-ratio: 1;
}
// @media (max-width:1150px ) {

//     .ChatBot .ChatInput .tableDropDownDiv{
//         width: 10rem;
//     }
//     .ChatBot .ChatInput .chatBotInputDiv {
//         width: calc(100% - 10rem);
//     }

// }
@media (max-width: 768px ) {

//     .ChatBot .ChatInput .ChatInnerInput {
//         width: 120%;
//         margin-left: -10%;
//     }
//     .ChatBot .ChatInput .tableDropDownDiv{
//         width: 10rem;
//     }
//     .ChatBot .ChatInput .chatBotInputDiv {
//         width: calc(100% - 10rem);
//     }
//     // .ChatBot .ChatInput .leftOptionFormOption, .ChatBot .ChatInput .rightOptionFormOption {
//     //     width: 4rem;
//     // }
// .ChatBot .ChatInput .centerOptionFormOption{
//     width: calc(100% - 14rem);
// }
.ChatBot .ChatInput .ChatInnerInput {
    
    width: calc(100% + 8rem);
    margin-left: -4rem;
}
}
@media (max-width: 550px ) {
    .ChatBot .ChatInput .rightOptionFormOption .surpriseButton .surpriseMeText{
        display: none;
    }
    .ChatBot .ChatInput .tableDropDownDiv .CustomDropdown.tableDropDown .mainTitle .dropDownValue{
        display: none;
    }
    .ChatBot .ChatInput .tableDropDownDiv {
        width: 3rem;
    }
    .ChatBot .ChatInput .ChatInnerInput {
        width: calc(100% + 8rem);
        margin-left: -4rem;
    }
}

@media (max-width: 350px ) {
    .ChatBot .ChatInput .ChatInnerInput {
        width: calc(100% + 10rem);
        margin-left: -5rem;
    }
}