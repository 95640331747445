$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .uploadConnection {
  height: 100%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  overflow: auto;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .uploadConnection .editConnectionForm .fieldAreaOuterContainer{
  height: calc(100% - 3.5rem);
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .uploadConnection .updloadConnectionheader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  // height: 5rem;
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .updloadConnectionTitle {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .updloadConnectionTitle .fileExtenstionIconImgDiv{
  height: 4rem;
  width: 4rem;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .updloadConnectionTitle .fileExtenstionIconImg{
  height: 90%;
  width: 90%;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .updloadConnectionDescription {
  text-align: center;
  font-size: 1rem;
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .uploadInputOuterContainer {
  display: flex;
  justify-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
  height: 100%;
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .uploadInputOuterContainer .uploadInputInnerContainer {
  display: flex;
  flex-direction: column;
  // background-color: $blue-light-2-color;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 2rem;
  height: 100%;
  border-radius: 6px;
  border: dashed $blue-color 2px;
  background-color: $blue-light-2-color;
  position: relative;
  transition: 150ms ease-in-out;
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .uploadInputOuterContainer .uploadInputInnerContainer .mainUploadInputFile {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .uploadInputOuterContainer .uploadInputInnerContainer.fileDropping {
  background-color: $black-hover-color;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .uploadInputOuterContainer .uploadInputInnerContainer .inputFileLoading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $black-hover-color;
}
