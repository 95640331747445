$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;



.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFormTitle{
  font-weight: 500;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFormInput{
  padding: .5rem 1rem;
  background: transparent;
  background: $gray-light-3-color;
  font-weight: 500;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 6px;
}

.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFormNumberInputContainer{
  width: 5rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .CustomDropdown .mainTitle{
  margin: 0;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFormTextArea{
  padding: 1rem 1rem;
  background: transparent;
  background: $gray-light-3-color;
  font-weight: 500;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  border-radius: 6px;
}

.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFileUploadContainer{
  width: 100%;
  padding: 1rem;
  background: transparent;
  background: $gray-light-3-color;
  outline: none;
  border: none;
  border-radius: 6px;
  resize: none;
  display: flex;
  gap: .5rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFileUploadContainerIcon{
  height: 1.25rem;
  width: 0;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFileUploadContainerLable{
  width: calc(100% - 2rem);
  font-weight: 500;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFormFileInput {
  cursor: pointer;
  &::file-selector-button{
    background-color: $blue-light-2-color;
    color: $blue-color;
    border-radius: 3px;
    border: 1px solid $blue-light-1-color;
    outline: none;
    transition: 100ms ease-in-out;
  }
  &:hover{
    &::file-selector-button{
      background-color: $blue-color;
      color: $white-color;
    }
  }
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFileUploadContainerRemoveButton{
  cursor: pointer;
  background-color: $red-1-color;
  // background-color: $gray-light-1-color;
  background-color: $secondary-color;

  color: $white-color;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  scale: 1.2;
  transition: 200ms ease-in-out;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFileUploadContainerRemoveButton:hover{
  background-color: $red-2-color;
  background-color: $secondary-color;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionFileUploadContainerRemoveButton:active{
  background-color: $red-3-color;
  background-color: $secondary-color;
}


.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .importTableTitle{
  text-align: center;
  font-size: 1.5rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable{
  display: flex;
  position: relative;
  width: 100%;
  padding: 0.5rem;
  border: solid 1px $gray-light-1-color;
  border-radius: 6px;
  gap: .5rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTableLoading{
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: $black-hover-color;
  color: $blue-color;
}
$listOfSheetsWidth :15rem;
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer{
  width: $listOfSheetsWidth;
  padding: 1rem 0;
  max-height: 65vh;
  @media only screen and (max-width: 1400px) {
  max-height: 70vh;
}
 
  // overflow: auto;
  // position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .searchInputDivContainer{
  width: 100%;
  // position: sticky;
  // top: -1rem;
  // padding-bottom: 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .SearchInputDiv{
  width: 90%;
}
  .DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .listOfSheetsScrollContainer{
    height: calc(100% - 3rem);
    overflow: auto;
  }
  .DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .listOfSheets{
  padding: .5rem .5rem ;
  cursor: pointer;
  border-radius: 6px;
  min-height: 2.5rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .listOfSheets{
  display: flex;
  gap: .75rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .listOfSheets .importIcon{
  width: 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .listOfSheetsTableNameDiv{
  width: calc(100% - 1rem );
  overflow: hidden;
  text-overflow: ellipsis;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .listOfSheets.selectedSheet{
  color: $blue-color;
  background-color: $blue-light-2-color;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .listOfSheets:hover{
  background-color: $gray-light-3-color;
}

.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .listOfSheets .importedTableIcon{
  color: $blue-color;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .listOfSheetsContainer .listOfSheets .nonImportedTableIcon{
  color:  $gray-light-1-color;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .displayTableArea{
  width: calc( 100% - #{$listOfSheetsWidth} );
  background-color: $gray-light-3-color;
  border-radius: 6px;
  overflow: auto;
  height: 100%;
  max-height: 65vh;
  @media only screen and (max-width: 1400px) {
    max-height: 70vh;
  }
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .connectionDataTable .displayTableArea .scrollingPart{
  max-height:none;
}
  .DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableConfigFieldsContainer{
  display: flex;
  flex-direction: column;
  background-color: $white-color;
  margin: .5rem;
  padding: 1rem;
  gap: .5rem;
  border-radius: 6px;
  flex-wrap: wrap;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableConfigFieldsContainer .tableConfigFields{
  display: grid;
  gap: .5rem;
  grid-template-columns: 1fr 1fr;
  border-radius: 6px;
  flex-wrap: wrap;
@media only screen and (max-width: 1200px) {
  grid-template-columns: 1fr ;
}

}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableConfigFieldsContainer .tableConfigFields .connectionFormOptionsInRow{
    gap: 1rem;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: .5rem 1rem;
}

.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableConfigFieldsContainer .tableConfigFieldsImportButtonDiv{
  display: flex;
  justify-content: flex-end;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableConfigFieldsContainer .tableConfigFieldsImportButtonDiv.reImportButton{
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableConfigFieldsContainer .tableConfigFieldsImportButtonDiv .reImportTextDiv{
  color: $red-1-color;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableConfigFieldsContainer .tableConfigFieldsImportButtonDiv .reImportRemove .reImportRemoveDropdown .mainTitle{
  background-color: $white-color;
  padding: 0rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableConfigFieldsContainer .tableConfigFieldsImportButtonDiv .reImportRemove .reImportRemoveDropdown .dropDownArray{
  width: 10rem;
  right: 0rem;
  top: 3rem;
}
  .DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableConfigFieldsContainer .tableConfigFieldsImportButtonDiv .primaryButton{
  text-wrap: nowrap;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableShowMorButtonDiv {
  padding: 0 1rem;
}

.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableShowMorButtonDiv .linkButton .showMoreDropDownIcon{
 transition: transform 200ms ease-in-out;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions .tableShowMorButtonDiv.moreConfigFieldButtonDiv .linkButton .showMoreDropDownIcon{
  transform: rotate(180deg);
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions  .DataSourceDisplayTable{
  border-radius: 6px;
  background-color: $white-color;
  margin: .5rem;
}
.DataConnectors .editConnectionsPopup .editConnectionForm .connectionFormOptions.SSLConfigFieldContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid $black-hover-color;
}