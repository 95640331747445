$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;

.chartPopUp .selectChartOuterDiv{
    position: relative;
    width: 100%;
    padding: 0 .5rem;
}
.chartPopUp .PopUpDivChild.restConfirmation .popUpOuterContaier .PopUpInnerContaier{
    // display: grid;
    // place-items: center; 
    min-height: auto;   
    min-width: 30%;
    // min-width: auto;
}
.chartPopUp .restConfirmation .restConfirmationChildernContainer{
    display: flex;
    flex-direction: column;
  gap: .5rem;
}
.chartPopUp .restConfirmation .warningIcon{
    display: flex;
    justify-content: center;
    font-size: 4rem;
    color: #FFC700;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .restConfirmation .titleDiv{
   justify-content: center;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .restConfirmation .discDiv{
   text-align: center;
   color: $red-1-color;
}
.chartPopUp .restConfirmation .primaryButton{
    padding: 0.5rem 2rem;
}
.chartPopUp .selectChartOuterDiv .primaryButton.widthFull{
    width: 100%;
}
.chartPopUp .selectChartOuterDiv .selectChangeChart{
    display: flex;
    gap: 1rem;
    align-items: center;

}
.chartPopUp .selectChartOuterDiv .selectChangeChart .selectChartIcon{
    cursor: pointer;

}
.chartPopUp .selectChartOuterDiv .selectChangeChart .selectChartIcon .nameIcon{
    color: $secondary-color;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: .8rem;
    letter-spacing: .5px;
}
.chartPopUp .selectChartOuterDiv .selectChangeChart .selectChartIcon img{
    height: 2rem;
}
.chartPopUp .selectChartOuterDiv .selectChangeChart .selectChartTitle{
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
}
.chartPopUp .selectChartOuterDiv .selectChangeChart .primaryButton{
    margin-left: auto;
}
.chartPopUp .selectChartOuterDiv .ChartChoose{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer{
    background-color: $white-color;
    margin-top: 2.5rem;
    max-height: 70%;
    width: 18rem;
    border-radius: 6px;
    padding: 1rem .5rem;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .cross{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0 .5rem;
    padding-bottom: 1rem;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .titleDiv{
    display: flex;
    height: 2rem;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 1.1rem;
    padding: 0 .5rem;

}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .titleDiv .rightOptions{
    display: flex;
    align-items: center;
    gap: .25rem;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .titleDiv .rightOptions .listToggle{
    display: flex;
    gap: .5rem;
    // background-color: $gray-light-2-color;
    border:solid 1px  $gray-light-2-color;
    padding: .5rem;
    border-radius: 6px;
    user-select: none;
    position: relative;
    overflow: hidden;
    transition: 300ms ease-in-out;

}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .titleDiv .rightOptions .listToggle span{
    height: 2rem;
    position: absolute;
    width: 1.6rem;
    left: 0;
    top: 0;
    background-color: $gray-light-1-color;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .titleDiv .rightOptions .listToggle.listOn span{
    left: auto;
    right: 0;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .titleDiv .rightOptions .listToggle .toggleOptions{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .titleDiv .rightOptions .listToggle .toggleOptions.toggleOptionIcon{
    height: .8rem;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .titleDiv .rightOptions .crossButton{
    cursor: pointer;
    font-size: 1.5rem;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .cross img{
    height: 1rem;
    cursor: pointer;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .titleDiv .arrowIcon{
    cursor: pointer;
    height: 1rem;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.5rem;
    padding: 1rem .5rem;
    max-height: 50vh;
    overflow: auto;
    overflow-x: hidden;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect{
    cursor: pointer;
    transition: 200ms ease-in-out;
    // z-index: 2;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect:hover{
    // z-index: 1;
    filter: drop-shadow(3px 3px 5px $black-hover-color)  contrast(90%);
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect.unChangable{
    filter: drop-shadow(0px 0px 5px $black-hover-color)  contrast(60%);
}
// .chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect.selected{
//     filter: drop-shadow(3px 3px 5px $black-hover-color)  contrast(70%);
// }
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect.selected .icon{
    box-shadow: inset 0 0 0px 3px $blue-color;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect.selected:hover{
    filter: drop-shadow(3px 3px 5px $black-hover-color)  contrast(10%);
}

.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect .icon{
    background: $white-color;
    border: solid #00000011 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    width: 100%;
    border-radius: 12px;
    filter: drop-shadow(3px 3px 5px $black-hover-color)  contrast(90%);

}


.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect:hover .chartForSelectToolTip{
    opacity: 1;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect .icon .nameIcon{
    color: $secondary-color;
    font-weight: 600;
    font-size: .7rem;
    letter-spacing: 1px;
}
// .chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect:nth-child(4n+1) .icon{
//     background: #FFE2E5;
// }
// .chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect:nth-child(4n+2) .icon{
//     background: #C9F7F5;
// }
// .chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect:nth-child(4n+3) .icon{
//     background: #FFF4DE;
// }
// .chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect:nth-child(4n+4) .icon{
//     background: #EEE5FF;
// }
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect .icon img{
    // width: 60%;
    width: 90%;
}
// .chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv .chartForSelect .chartDetails{
//     position: absolute;
//     z-index: 5;
// }

.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list{
    grid-template-columns: 1fr;
    gap: .15rem;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: .25rem .25rem;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect .textDiv{
    width: 100%;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect:hover{
    filter: drop-shadow(5px 5px 8px $black-hover-color)  contrast(95%);
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect.selected{
    filter: drop-shadow(5px 5px 8px $black-hover-color)  contrast(100%);
    background-color: $black-hover-color;
    border-radius: 6px;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect.selected .icon{
    box-shadow: none;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect.unChangable{
    filter: drop-shadow(5px 5px 5px $black-hover-color)  contrast(70%);
    // background-color: $black-hover-color;
    color: $blue-light-1-color;
    border-radius: 6px;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect.selected:hover{
    filter: drop-shadow(5px 5px 5px $black-hover-color)  contrast(100%);
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect .icon{
    width: 3.5rem;
    aspect-ratio: 1;
}


.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect .textDiv .title{
    font-size: 1rem;
    // line-height: 1;
}
.chartPopUp .selectChartOuterDiv .ChartChoose .chartChooseContainer .chartSelectionDiv.list .chartForSelect .textDiv .subtitle{
    font-size: .7rem;
    color: $secondary-color;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
