$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;


.dataSourceConnectionContainer .dataSourceConnection{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  
  @media (max-width: 950px) {
      grid-template-columns:  1fr 1fr 1fr;
  }
  @media (max-width: 650px) {
      grid-template-columns:  1fr 1fr;
  }
  @media (max-width: 450px) {
      grid-template-columns:  1fr;
  }
}


.dataSourceConnectionContainer .dataSourceConnection .connectionCardContainer{
  border: solid $gray-light-1-color 1px;
  // border: solid $secondary-color 1px;
  border: solid $blue-light-1-color 1px;
  padding: 1rem;
  border-radius: 6px;
  border-style: dashed;
  display: grid;
  gap: .25rem;
  grid-template-columns: 5rem 1fr ;
  grid-template-rows: 1fr 1fr;
  cursor: pointer;
  transition: 100ms ease-in-out;
}
.dataSourceConnectionContainer .dataSourceConnection .connectionCardContainer:hover{
  background-color: $blue-light-2-color;
  .connectiionCardTitle{
    color: $blue-color;

  }
}
.dataSourceConnectionContainer .dataSourceConnection .connectionCardContainer .connectiionCardIcon{
  grid-row: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dataSourceConnectionContainer .dataSourceConnection .connectionCardContainer .connectiionCardIcon img{
 width: 70%;
 aspect-ratio: 1;
 object-fit: contain;
}
.dataSourceConnectionContainer .dataSourceConnection .connectionCardContainer .connectiionCardTitle{
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
}
.dataSourceConnectionContainer .dataSourceConnection .connectionCardContainer .connectiionCardDiscription{
  font-size: .9rem;
  font-weight: 500;
  color: $secondary-color;
}
.dataSourceConnectionContainer.flexCenter{
  width: 100%;
  display: flex;
  justify-content: center;
}
.dataSourceConnectionContainer .dataSourceConnectionInnerContainer{
  width: 70%;
  @media only screen and (max-width: 1400px) {
    width: 90%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.dataSourceConnectionContainer .dataSourceConnectionInnerContainer.tableConfigWidthContainer{
  // width:  clamp(50rem, 50vw, 2rem) ;
  width: 90%;
  @media only screen and (max-width: 1400px) {
    width: 100%;
  }

}