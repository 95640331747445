$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;




.DataConnectors .PopUpDivChild.editConnectionsPopup .editConnectionsPopupChildernContainer{
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: .5rem;
}
.DataConnectors .PopUpDivChild.editConnectionsPopup .editConnectionsPopupChildernContainer .popupTitleDiv .removeButton{
  white-space: nowrap;
}
.DataConnectors .editConnectionsPopup .popupTitleDiv .popupTitle{
  text-align: start;
  font-size: 1.5rem;
  font-weight: 600;
  // padding: 0 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer{
  height: calc(100%  - 3rem);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .tableDisplayPopup .PopUpInnerContaier{
  max-width: 80%;
  min-height: 50%;
  max-height: 80%;
  height: auto;
  width: auto;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .ConfirmationDiv .PopUpInnerContaier{
  max-width: 80%;
  min-width: auto;
  min-height: 10%;
  max-height: 80%;
  height: auto;
  width: auto;
}
.DataConnectors .editConnectionsPopup .editConnectionsPopupPopUpInnerContaier{
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}


.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .editConnectionsInnerContainer{
  display: flex;
  height: 100%;
  width: 100%;
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .editConnectionsHeader{
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .editConnectionsHeader .crossButton{
  background-color: $gray-light-2-color;
  color: $secondary-color;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  transition: 150ms ease-in-out;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .editConnectionsHeader .crossButton:hover{
  background-color: $blue-color;
  color: $white-color;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .editConnectionsHeader .editConnectionsListTitle{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .editConnectionsHeader .editConnectionsListTitle{
  display: flex;
  flex-direction: column;
  align-items: center;
}
$connectionGroupSelectionContainerheight : 3rem;
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer.noConnectionToggleStrip .connectionGroupSelectionContainer{
  height: 0;
  display: none;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer.noConnectionToggleStrip .connectionMainContainer{
  height: 100%;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionGroupSelectionContainer{
  display: flex;
  gap: 0.5rem;
  height: $connectionGroupSelectionContainerheight;
  width: 100%;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionGroupSelectionContainer .connectionGroupToggleContainer{
  display: flex;
  gap: 0.5rem;
  height: 100%;
  width: 100%;
  align-items: flex-end;
  background-color: $blue-color;
  border-radius: 6px;
  padding: 0 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionsActionButtons{
  display: flex;
  gap: 1rem;
  height: 100%;
  width: 100%;
  // padding: 0 1rem;
}

.DataConnectors .editConnectionsPopup .actionButton {
  background-color: $gray-light-2-color;
  color: $secondary-color;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  transition: 150ms ease-in-out;
}
.DataConnectors .editConnectionsPopup .actionButton:hover {
  background-color: $blue-color;
  color: $white-color;
}
  $slectedBorderRadius:10px;
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionGroupOption{
  color: $white-color;
  width: 8rem;
  display: flex;
  align-items: center;
  height: 90%;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  border-radius: $slectedBorderRadius $slectedBorderRadius 0 0 ;
  cursor: pointer;
  position: relative;
  transition: 100ms ease-in-out;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionGroupOption.selectedConnectionGroup{
  color: $blue-color;
  background-color: $white-color;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionGroupOption .left-curve {
  position: absolute;
  bottom: 0px;
  left: -$slectedBorderRadius;
  height: 100%;
  width: $slectedBorderRadius;
  background: $white-color;
  transition: 100ms ease-in-out;
  opacity: 0;
  // display: none;
  &::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: $slectedBorderRadius;
    background: $blue-color;
  }
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionGroupOption .right-curve {
  position: absolute;
  right: -$slectedBorderRadius;
  top: 0px;
  height: 100%;
  width: $slectedBorderRadius;
  background: $white-color;
  transition: 100ms ease-in-out;
  // display: none;
  opacity: 0;
  &::before {
    content: "";
    right: 0;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    border-bottom-left-radius: $slectedBorderRadius;
    background: $blue-color;
  }
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionGroupOption.selectedConnectionGroup .left-curve,
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionGroupOption.selectedConnectionGroup .right-curve {
  // display: block;
  opacity: 1;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer{
  height: calc( 100% - #{$connectionGroupSelectionContainerheight} - 1rem);
  display: flex;
  justify-content: center;
  overflow: auto;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .editFileContentLoadedOuterContainer{
  width: 100%;
  display: flex;
}
$appendContainerWidth:30rem;
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .editFileContentLoaded{
  height: calc(100% - 3rem);
  width: 100%;
  padding: 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .editFileContentLoadedOuterContainer.appendContainerOpen .editFileContentLoaded{
  width: calc(100% - #{$appendContainerWidth});
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer{
  display: none;
  width: $appendContainerWidth;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .editFileContentLoadedOuterContainer.appendContainerOpen .appendContainer{
  display: flex;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .updloadConnectionDescription{
//  height: 2rem;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: .5rem;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .updloadConnectionDescription span img{
 height: 1.75rem;
 width: 1.75rem;
 object-fit: contain;
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .editConnectionButtonDiv{
  height: 2rem;
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem;
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .editConnectionFormContainer{
 height: calc(100% - 4rem);
 overflow: auto;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .editConnectionFormContainer.noHeading{
 height: calc(100% - 0rem );
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .editConnectionFormContainer.flexCenter{
  display: flex;
  justify-content: center;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .editConnectionFormContainer .editConnectionFormInnerContainer{
  width: 70%;
  height: 100%;
 overflow: auto;

}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
$uploadAppendAreaHeight :23rem;
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .uploadAppendArea {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  height: $uploadAppendAreaHeight;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendContainerTilte{
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: 500;
  text-align: center;
 }
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendInputContainer{
  height:15rem;
 }
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendInputContainer .updloadConnectionTitle{
  font-size: 1.5rem;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendInputContainer .updloadConnectionDescription{
  font-size: 1rem;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer {
 display: flex;
 flex-direction: column;
  padding: .5rem;
  background-color: $gray-light-3-color;
  gap: .5rem;
  border-radius: 6px;
  height: calc(100% - #{$uploadAppendAreaHeight} - 2rem);
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryName{
  font-weight: 500;
  font-size: 1.2rem;
  height: 2rem;
  text-align: center;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList{
  height: calc(100% - 2rem);
  overflow: auto;
  border-radius: 6px;
  padding: .5rem;
  background-color: $white-color;
  display: flex;
  gap: .5rem;
  flex-direction: column;
}

.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList .appendHistoryListItem{
  border: solid $gray-light-1-color 1px;
  position: relative;
  padding: 1rem;
  border-radius: 6px;
  border-style: dashed;
  display: grid;
  gap: .25rem;
  grid-template-columns: 5rem 1fr ;
  grid-template-rows: 1fr 1fr;
  cursor: pointer;
  transition: 100ms ease-in-out;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList .appendHistoryListItem:hover{
  background-color: $blue-light-2-color;
  .connectiionCardTitle{
    color: $blue-color;

  }
  .appentHistoryRemoveButton{
  scale: 1;

  }
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList .appendHistoryListItem .appentHistoryRemoveButton{
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  color: $red-1-color;
  height: 1.75rem;
  width: 1.75rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border-radius: 1.75rem;
  margin: .25rem;  
  scale: 0;
  transition: 200ms ease-in-out;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList .appendHistoryListItem .appentHistoryRemoveButton:hover{
  color: $red-2-color;
  scale: 1.1;

}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList .appendHistoryListItem .appentHistoryRemoveButton:active{
  color: $red-3-color;
}
  .DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList .appendHistoryListItem .appendHistoryListItemIcon{
  grid-row: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList .appendHistoryListItem .appendHistoryListItemIcon img{
 width: 70%;
 aspect-ratio: 1;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList .appendHistoryListItem .appendHistoryListItemTitle{
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
}
.DataConnectors .editConnectionsPopup .editConnectionsOuterContainer .connectionMainContainer .appendContainer .appendHistoryContainer .appendHistoryList .appendHistoryListItem .appendHistoryListItemDiscription{
  font-size: .9rem;
  font-weight: 500;
  color: $secondary-color;
}