$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;

.kpiTargetPointContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.kpiTargetPointContainer .kpiTargetPointDiv{
  display: flex;
  gap: .5rem;
}
.kpiTargetPointContainer .kpiTargetPointDiv .targetPointLable{
  width: calc(100% - 1rem - 1rem - 1rem);
}
.kpiTargetPointContainer .kpiTargetPointDiv .targetPointColor{
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kpiTargetPointContainer .kpiTargetPointDiv .targetPointColor .targetPointColorDiv{
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
  cursor: pointer;
}
.kpiTargetPointContainer .kpiTargetPointDiv .targetPointRemove{
  width: 1rem;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}
.kpiTargetPointContainer .kpiTargetPointDiv .targetPointRemove.targetPointRemoveDisable{
 opacity: .5;
  cursor: auto;
}
