$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;

.DashboardSettings .mainContainer .sectionContainer {
    background: $gray-light-1-color;
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
}
.DashboardSettings .mainContainer .sectionContainer .sectionItem.noSection{
    background-color: transparent;
}
.DashboardSettings .mainContainer .sectionContainer .sectionItem{
    background-color: $white-color;
    margin: 0.25rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    white-space: nowrap;
    position: relative;
}
.DashboardSettings .mainContainer .sectionContainer .sectionItem .removeaxisItem{
    cursor: pointer;
    height: 1rem;
    padding: 0.12rem;
    margin-left: auto;
}