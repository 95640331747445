$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;



.DashboardSettings{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    // overflow: hidden;
    z-index: 999;
    background-color: #FFFFFF;
    color: $primary-color;
    opacity: 0;
    transform: scale(0);
    animation: chartPopUp 300ms ease-in-out forwards;
  }

 
@keyframes chartPopUp {
    0%{
        transform: scale(0);
        opacity: 0;
    }
    100%{
        transform: scale(1);
        opacity: 1;
    }
}

// Buttons
.DashboardSettings .primaryButton{
    border: none;
    outline: none;
    background: $blue-color;
    color: $white-color;
    font-weight: 600;
    font-size: .9rem;
    border-radius: 6px;
    height: 3rem;
    padding: 0.5rem 1rem;
    transition: all 200ms ease-in-out;
}
.DashboardSettings .primaryButton:disabled{
    background: $black-hover-color;
}
.DashboardSettings .primaryButton:disabled:hover{
    background: $black-hover-color;
}
.DashboardSettings .primaryButton:hover{
    background: #0091e4;
}
.DashboardSettings .primaryButton:active{
    background: #007cc4;
}
// removeButton
.DashboardSettings .primaryButton.removeButton{
    background: $red-1-color;
}
.DashboardSettings .primaryButton.removeButton:hover{
    background: $red-2-color;
}
.DashboardSettings .primaryButton.removeButton:active{
    background: $red-3-color;
}

.DashboardSettings .primaryColor{
    background: $blue-color;
    transition: 200ms ease-in-out;
}
.DashboardSettings .primaryColor:hover{
    background: #0091e4;
}
.DashboardSettings .primaryColor:active{
    background: #007cc4;
}

.DashboardSettings .secondaryColor{
    background: $secondary-color;
    transition: 200ms ease-in-out;
}
.DashboardSettings .secondaryColor:hover{
    background: $red-1-color;
}
.DashboardSettings .secondaryColor:active{
    background: $red-1-color;
}


.DashboardSettings .titleDiv{
    font-weight: 500;
}

.MainLoaderInner div {
  position: absolute;
  animation: MainLoaderInner 1s linear infinite;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  box-shadow: 0 3.6px 0 0 #262323;
  transform-origin: 72px 73.8px;
}
// .MainLoader {
//   width: 200px;
//   height: 200px;
//   display: inline-block;
//   overflow: hidden;
//   background: rgba(255, 255, 255, 0);
// }
// .MainLoaderInner {
//   width: 100%;
//   height: 100%;
//   position: relative;
//   transform: translateZ(0) scale(1);
//   backface-visibility: hidden;
//   transform-origin: 0 0; /* see note above */
// }
// .MainLoaderInner div { box-sizing: content-box; }

// @keyframes MainLoaderInner {
//     0% { transform: rotate(0deg) }
//     50% { transform: rotate(180deg) }
//     100% { transform: rotate(360deg) }
//   }
// Buttons
.DashboardSettings .primaryButtonFlexDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 0.25rem;
    gap: 1rem;
    height: 3rem;
}
.DashboardSettings .primaryButtonSmall{
    border: none;
    outline: none;
    background: $blue-color;
    color: $white-color;
    padding: 0.2rem 1rem;
    font-size: .9rem;
    border-radius: 6px;
    transition: all 200ms ease-in-out;
}
.DashboardSettings .primaryButtonSmall:disabled{
    background: $black-hover-color;
}
.DashboardSettings .primaryButtonSmall:disabled:hover{
    background: $black-hover-color;
}
.DashboardSettings .primaryButtonSmall:hover{
    background: #0091e4;
}
.DashboardSettings .primaryButtonSmall:active{
    background: #007cc4;
}
.DashboardSettings .primaryInput{
    width: 100%;
    padding:  .5rem .75rem;
    background: transparent;
    background: $gray-light-3-color;
    outline: none;
    border: none;
    border-radius: 6px;
    margin: 0.5rem 0;
}
// Divs
.DashboardSettings .MainUpperHeader{
    box-sizing: border-box;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    height: 4rem;
    grid-gap: 1rem;
    gap: 1rem;
    border-bottom: 1px solid $gray-light-1-color;
    font-size: 1.5rem;
}
.DashboardSettings .MainUpperHeader .title{
    font-weight: 600;
}
.DashboardSettings .MainUpperHeader .subTitle{
    font-weight: 500;
    color: $secondary-color;
}
.DashboardSettings .MainUpperHeader .goleftSide{
    margin-left: auto;
}
.DashboardSettings .MainUpperHeader .deleteButtonDiv .primaryButton{
    padding: .25rem 2rem;
}
.DashboardSettings .MainUpperHeader .saveButtonDiv .primaryButton{
    padding: .25rem 2rem;
}

.DashboardSettings .MainUpperHeader .closeButton{
    background-color: $gray-light-2-color;
    color: $secondary-color;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    transition: 150ms ease-in-out;
  }
  .DashboardSettings .MainUpperHeader .closeButton:hover{
    background-color: $blue-color;
    color: $white-color;
  }
.DashboardSettings .MainUpperHeader .confirmationContainer{
    position: absolute;
    background-color: $black-hover-color;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.DashboardSettings .MainUpperHeader .confirmationContainer .confirmationDiv{
    background-color: white;
    min-width: 10rem;
    padding: 1rem;
    border-radius: 6px;
}
.DashboardSettings .MainUpperHeader .confirmationContainer .confirmationDiv .msg{
    text-align: center;
    margin-bottom: 1rem;
}
.DashboardSettings .MainUpperHeader .confirmationContainer .confirmationDiv .ButtonContainer{
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.DashboardSettings .MainUpperHeader .confirmationContainer .confirmationDiv .ButtonContainer .primaryButtonSmall{
    padding: .5rem 1rem;
}
.DashboardSettings .mainContainer{
    height: calc(100vh - 4rem);
    display: flex;
}
$mainColumnWidth:20rem;
$toggleColumnwidth:3rem;
.DashboardSettings .mainContainer .firstColumn{
    height: 100%;
    width: 0;
    box-sizing: border-box;
    gap: 0.5rem;
    flex-direction: column;
    display: flex;
    padding: 0 .5rem;
    background: #EEF0F8;
    border-right: 1px solid $gray-light-1-color;
    overflow: hidden;
    animation: firstColumn 300ms forwards ease-in-out ;
}
@keyframes firstColumn {
    0%{
        width: 0;
    }
    100%{
        width: $mainColumnWidth;
    }
}
.DashboardSettings .mainContainer .firstColumn.close{
    animation: firstColumnClose 300ms forwards ease-in-out ;
}
@keyframes firstColumnClose {
    0%{
        width: $mainColumnWidth;
        padding: 0 .5rem;
    }
    100%{
        padding: 0;
        width: 0;
    }
}
.DashboardSettings .mainContainer .secondColumn{
    height: 100%;
    width: 20rem;
    box-sizing: border-box;
    gap: 0.5rem;
    flex-direction: column;
    display: flex;
    padding: 0 .5rem;
    overflow: auto;
    background: #EEF0F8;
    border-right: 1px solid $gray-light-1-color;
    animation: secondColumn 300ms forwards ease-in-out ;
}
@keyframes secondColumn {
    0%{
        width: 0;
    }
    100%{
        width: $mainColumnWidth;
    }
}
.DashboardSettings .mainContainer .secondColumn.close{
    animation: secondColumnClose 300ms forwards ease-in-out ;
}
@keyframes secondColumnClose {
    0%{
        width: $mainColumnWidth;
        padding: 0 .5rem;
    }
    100%{
        padding: 0;
        width: 0;
    }
}
.DashboardSettings .mainContainer .thirdColumn {
    padding: 1rem;
    width: calc(100vw  - #{$toggleColumnwidth} );
    transition: 200ms ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.DashboardSettings .mainContainer .thirdColumn.oneColumnOpen {
    width: calc(100vw - #{$mainColumnWidth} - #{$toggleColumnwidth} );
}
.DashboardSettings .mainContainer .thirdColumn.twoColumnOpen {
    width: calc(100vw - #{$mainColumnWidth} - #{$mainColumnWidth} );
}

$firstColumnScrollHeight:4rem;
//firstColumn
.DashboardSettings .mainContainer .firstColumn .staticDiv{
    display: flex;
    flex-direction: column;
    height: $firstColumnScrollHeight;
    gap: 0.25rem;
    
}
.DashboardSettings .mainContainer .firstColumn .scrollableDiv{
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$firstColumnScrollHeight});
    padding: 0 .5rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    overflow: auto;
    gap: 1rem;
}
.DashboardSettings .mainContainer .mainTitleDiv{
    display: flex;
    height: 4rem;
    padding: 0 .5rem;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
}
.DashboardSettings .mainContainer .mainTitleDiv .arrowIcon{
    cursor: pointer;
}
.DashboardSettings .mainContainer .firstColumn .searchBox{
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 .5rem;
    height: 4rem;
}
.DashboardSettings .mainContainer .firstColumn .searchBox input[type=text]{
    border-radius: 6px;
    border: none;
    outline: none;
    width: 100%;
    padding: 0.5rem 1rem;
    padding-right: 2.8rem;
}
.DashboardSettings .mainContainer .firstColumn .searchBox input[type=text]::placeholder{
    font-weight: 500;
}
.DashboardSettings .mainContainer .firstColumn .searchBox .searchIcon{
    position: absolute;
    right: 0.8rem;
}

.DashboardSettings .mainContainer .firstColumn  .searchBox .popUpOptions{
    top: 4rem;
}
.DashboardSettings .mainContainer .firstColumn  .searchBox .popUpOptions.open{
    max-height: 50vh;
}
.DashboardSettings .mainContainer .firstColumn .sourceNameDiv{
    display: flex;
    align-items: center;
    border-radius: 6px;
    // width: 100%;
    padding: 0.5rem 1rem;
    padding-right: 2.8rem;
    background-color: $white-color;
}

.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems{
    background: $gray-light-1-color;
    border-radius: 6px;
    background: #DFE2EF;
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .draggableWithTitleMainTitle{
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    display: flex;
    cursor: pointer;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .draggableWithTitleMainTitle span:hover{
    text-decoration: underline;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .draggableWithTitleMainTitle img{
    margin-left: auto;
    transition: 200ms ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    height: .5rem;
    cursor: pointer;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems.open .draggableWithTitleMainTitle img{
    transform: rotate(180deg);
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .draggableWithTitleItemList{
    // display: none;
    max-height: 0px;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems.open .draggableWithTitleItemList{
    display: block;
    max-height: 9999em;
    transition: max-height 3s ease-in-out;

}

.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems.open .draggableWithTitleItemList .dragableContainer .viewMore{
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems{
    background: $white-color;
    margin: 0.5rem 0;
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: .5rem;
    height: 2.5rem;
    padding: 0.5rem .75rem;
    border-radius: 6px;
    position: relative;

    // padding: 0.8rem 1rem;
    transition: 100ms ease-in-out;
}


.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems .form-check-input{
    width: 1.5rem;
    height: 1.5rem;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems .draggingSvg{
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $secondary-color;
    font-size: 1.2rem;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems .dragableItemsTitle{
    width: calc( 100% - 4.2rem );
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems .dragableItemsTitleIcon{
    width: 2rem;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems .dragableItemsTitleIcon img{
    width: 100%;
    aspect-ratio: 1/1;
    height: 100%;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems .dragableItemsDataType{
    position: absolute;
    right: .3rem;
    font-size: .8rem;
    font-weight: 500;
    bottom: .1rem;
    // background: $white-color;
    // padding: .20rem;
    border-radius: 6px;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems:hover{
    background: $gray-light-2-color;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems.selected{
    background: $gray-light-2-color;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems .form-check{
    margin-bottom: 0;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems .form-check .form-check-label{
    width: 100%;
}
.DashboardSettings .mainContainer .firstColumn .draggableWithTitleContainerDiv .draggableWithTitleItems .dragableItems .tableItemTitleDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
}
.DashboardSettings .mainContainer .firstColumn .chartFilterByType{
    height: 3.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 0 .5rem;
    padding-left: 1rem;
}
.DashboardSettings .mainContainer .firstColumn .chartFilterByType .chartFilterByTypeTitle{
    width: 8rem;
    font-weight: 500;
}

.DashboardSettings .mainContainer .firstColumn .chartFilterByType .chartFilterByTypeDropDown{
    width: calc( 100% - 8rem);
}


$secondColumnScrollHeight:10rem;

// secondColumn
.DashboardSettings .mainContainer .secondColumn .staticDiv{
    display: flex;
    flex-direction: column;
    height: #{$secondColumnScrollHeight};
    gap: 0.5rem;
}
.DashboardSettings .mainContainer .secondColumn .staticDiv.WithoutStyleToggle{
    height: calc(#{$secondColumnScrollHeight} - 6rem);
}
.DashboardSettings .mainContainer .secondColumn .scrollableDiv{
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$secondColumnScrollHeight});
    padding: 1rem .5rem;
    overflow: auto;
    gap: 1rem;
}
.DashboardSettings .mainContainer .secondColumn .scrollableDiv.WithoutStyleToggle{
    height: calc(100% - #{$secondColumnScrollHeight} + 6rem);
}
.DashboardSettings .mainContainer .secondColumn .MainTitle{
    font-weight: 500;
    font-size: 1.4rem;
    height: 2rem;
    margin-bottom: 0.5rem
}
.DashboardSettings .mainContainer .secondColumn .MainTitle.inFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.DashboardSettings .mainContainer .secondColumn .MainTitle .resetButton{
    width: 2rem;
    aspect-ratio: 1;
    background-color: $white-color;
    justify-content: center;
    display: flex;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;
    transition: 300ms ease-in-out;
}
.DashboardSettings .mainContainer .secondColumn .MainTitle .resetButton:hover{
    background-color: $gray-light-1-color;
}

.DashboardSettings .mainContainer .secondColumn .MainTitle .resetButton img{
    height: 65%;
}
.DashboardSettings .mainContainer .secondColumn .chartWidth{
    height: 3.5rem;
    padding: 0 .5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
}
.DashboardSettings .mainContainer .secondColumn .chartWidth .chartWidthTitle{
    width: 8rem;
    font-weight: 500;
}

.DashboardSettings .mainContainer .secondColumn .chartWidth .chartWidthDropDown{
    width: calc( 100% - 8rem);
}
.DashboardSettings .mainContainer .secondColumn .toggleButtonContainer{
    display: flex;
    height: 5rem;
    align-items: baseline;
    font-weight: 500;
    font-size: 1.4rem;
    margin-top: auto;
    padding: 0 .5rem;
}
.DashboardSettings .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv{
    display: flex;
    background: #ffffff99;
    border-radius: 4px;
}
.DashboardSettings .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton{
    display: flex;
    flex-direction: column;
    width: 6.5rem;
    height: 4.5rem;
    justify-content: space-evenly;
    text-align: center;
    border-radius: 4px;
    font-size: 1.2rem;
    z-index: 2;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.DashboardSettings .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton.active{
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    background: #FFFFFF;
}
.DashboardSettings .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton.disable{
    color: #7E8299;
    filter: grayscale(100%);
}
.DashboardSettings .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton img{
    height: 1.8rem;
}
.DashboardSettings .mainContainer .secondColumn .toggleButtonContainer .toggleButtonDiv .toggleButton.style img{
    height: 1.5rem;
    margin-top: 0.3rem;
}


// axisInnerDivAdd
.DashboardSettings .mainContainer .secondColumn .textAndToggle {
    display: flex;
    height: 2.5rem;
    font-weight: 500;
    padding-left: 1rem;
    align-items: center;
}
.DashboardSettings .mainContainer .secondColumn .textAndToggle .toggleDiv{
    margin-left: auto;
}


//thirdColumn
.DashboardSettings .mainContainer .thirdColumn{
    overflow: auto;
}
.DashboardSettings .mainContainer .thirdColumn .uppperMainTitle{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
}
.DashboardSettings .mainContainer .thirdColumn .chartNameDiv {
    display: grid;
    gap: 1rem;
    grid-template-columns: auto 1fr;
    place-items: center;
}

.DashboardSettings .mainContainer .thirdColumn .chartNameDiv .titleDiv{
    font-weight: 500;
}
.DashboardSettings .mainContainer .thirdColumn .chartNameDiv input{
    width: 100%;
    padding: 0.5rem 0.75rem;
    background: transparent;
    background: #F5F8FA;
    outline: none;
    border: none;
    border-radius: 6px;
}
.DashboardSettings .mainContainer .thirdColumn .chartDescriptionDiv {
    display: grid;
    gap: 1rem;
}

.DashboardSettings .mainContainer .thirdColumn .chartDescriptionDiv .titleDiv{
    font-weight: 500;
}
.DashboardSettings .mainContainer .thirdColumn .chartDescriptionDiv textarea{
    width: 100%;
    padding: 0.5rem 0.75rem;
    background: transparent;
    background: #F5F8FA;
    outline: none;
    border: none;
    border-radius: 6px;
    resize: none;
}

.DashboardSettings .mainContainer .thirdColumn .chartPopulateButtonDiv {
    flex-direction: column;
    gap: 1rem;
}
.DashboardSettings .mainContainer .thirdColumn .chartPopulateButtonDiv .primaryButton{
    padding: .5rem 2rem;
}


// Scrolll


// /*
//  *  STYLE 8
//  */

//  .DashboardSettings ::-webkit-scrollbar-track
//  {
//      border: 1px solid black;
//      background-color: #F5F5F5;
//  }
 
//  .DashboardSettings ::-webkit-scrollbar
//  {
//      width: 10px;
//      background-color: #F5F5F5;
//  }
 
//  .DashboardSettings ::-webkit-scrollbar-thumb
//  {
//      background-color: #000000;	
//  }
 
 
/*
 *  STYLE 7
 */

// .DashboardSettings ::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	background-color: #F5F5F5;
// 	border-radius: 10px;
// }

// .DashboardSettings ::-webkit-scrollbar
// {
// 	width: 10px;
// 	background-color: #F5F5F5;
// }

// .DashboardSettings ::-webkit-scrollbar-thumb
// {
// 	border-radius: 10px;
// 	background-image: -webkit-gradient(linear,
// 									   left bottom,
// 									   left top,
// 									   color-stop(0.44, rgb(122,153,217)),
// 									   color-stop(0.72, rgb(73,125,189)),
// 									   color-stop(0.86, rgb(28,58,148)));
// }


/* firefox */
//firefox

.DashboardSettings .mainContainer{
	$scrollbar-2-thumb-width: 4px;
	$scrollbar-2-thumb-color: $secondary-color;
	$scrollbar-2-track-color: $black-hover-color  ;
	
    // Chrome & Opera
    & ::-webkit-scrollbar {
       width: $scrollbar-2-thumb-width;
        height: $scrollbar-2-thumb-width;
        //  display: none;
       &-track {
           background-color: $scrollbar-2-track-color;
       }

       &-thumb {
          background-color: $scrollbar-2-thumb-color;
       }
		 
		 &-track,
		 &-thumb {
			 border-radius: 12px;
		 }
    }
    

    // Firefox
    scrollbar-width: thin !important;
    scrollbar-color: $scrollbar-2-thumb-color $scrollbar-2-track-color !important;
    & :hover{
        scrollbar-width: thin !important;
        scrollbar-color: $scrollbar-2-thumb-color $scrollbar-2-track-color !important;
    }
}


 
 /*
  *  STYLE 9
  */
 
//  #style-9::-webkit-scrollbar-track
//  {
//      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//      background-color: #F5F5F5;
//  }
 
//  #style-9::-webkit-scrollbar
//  {
//      width: 10px;
//      background-color: #F5F5F5;
//  }
 
//  #style-9::-webkit-scrollbar-thumb
//  {
//      background-color: #F90;	
//      background-image: -webkit-linear-gradient(90deg,
//                                                rgba(255, 255, 255, .2) 25%,
//                                                transparent 25%,
//                                                transparent 50%,
//                                                rgba(255, 255, 255, .2) 50%,
//                                                rgba(255, 255, 255, .2) 75%,
//                                                transparent 75%,
//                                                transparent)
//  }
 