$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;




.chartPopUp .mainContainer .scrollableDiv .formatterDivContainer {
    display: flex;
    gap: .5rem;
    flex-direction: column;
}
.chartPopUp .mainContainer .scrollableDiv .formatterDivContainer.formatterDivWithBorder {
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    border: solid 1px $gray-light-1-color;
}
.chartPopUp .mainContainer .scrollableDiv .formatterDivContainer .formatterToggleContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.5rem;

}
.chartPopUp .mainContainer .scrollableDiv .formatterDivContainer .formatterToggleContainer .formatterToggles{
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 0.25rem;

}
.chartPopUp .mainContainer .scrollableDiv .formatterDivContainer .formatterToggleContainer .formatterToggles .titleDiv{
    width: 5.75rem;
}
