$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;




// dragableContainer
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer{
    background: $gray-light-1-color;
    border-radius: 6px;
    padding: .25rem.5rem;
    // gap: .5rem;
    display: flex;
    min-height: 3rem;
    flex-direction: column;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .dragableItem{
    background-color: $white-color;
    margin: .25rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    white-space: nowrap;
    position: relative;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .dragableItem .elemTitle{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .dragableItem span{
    margin-right: auto;
    font-size: .8rem;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chartPopUp .PopUpDiv .popUpOuterContaier .titleDiv{
    font-weight: 500;
}
.chartPopUp .PopUpDiv .popUpOuterContaier .saveButton{
    margin-top: auto;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: flex-end;
}
.chartPopUp .mainContainer .popUpOptions{
    background: $white-color;
    position: absolute;
    right: 0;
    top: 3rem;
    border-radius: 6px;
    overflow: auto;
    z-index: 5;
    box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-height: 0px;
    width: 15.5rem;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
@media (min-width: 992px) {
    .chartPopUp .mainContainer .popUpOptions{
     width: 16.5rem;
    }
}
.chartPopUp .mainContainer .popUpOptions.open{
    // min-height: 3rem;
    max-height: 15em;
    max-height: 35em;
    overflow: auto;
    transition: max-height 300ms ease-in-out;
}
.chartPopUp .mainContainer .popUpOptions ul{
    list-style: none;
    margin: 0; 
    padding: 0; 
}
.chartPopUp .mainContainer .popUpOptions ul hr{
    width: 90%;
    margin: 0 5%;
}
.chartPopUp .mainContainer .popUpOptions ul li{
    text-align: center;
    padding: .5rem .75rem;
    text-wrap: balance;
    transition: 200ms ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.chartPopUp .mainContainer .popUpOptions ul li.disable{
    color: $gray-light-1-color;
    cursor: auto;
    user-select: none;
}
.chartPopUp .mainContainer .popUpOptions ul li.selected{
    background-color: $gray-light-1-color;
}
.chartPopUp .mainContainer .popUpOptions ul li:hover{
    background-color: $gray-light-2-color;
}
.chartPopUp .mainContainer .popUpOptions ul li:active{
    background-color: $gray-light-3-color;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .boxDropDownIcon{
    margin-left: auto;
    cursor: pointer;
    padding: 0.5rem 0.25rem;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .removeaxisItem{
    cursor: pointer;
    height: 1rem;
    padding: .12rem;
}
.chartPopUp .PopUpDiv.PCT_BY_GROUP .popUpOuterContaier .PopUpInnerContaier{
    width: 30rem;
    min-width: 30%;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .PCT_BY_GROUP .mainTitleDiv{
    height: 3rem;
    padding-bottom: .75rem;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .PCT_BY_GROUP .titleDiv span{
    margin:  0 .25rem;
    margin-right: .5rem;
    font-size: 1rem;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .toggleTitleDiv{
    display: flex;
    border-radius: 6px;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .toggleTitleDiv span{
    border-radius: 0;
    padding: .5rem 1rem;
    cursor: pointer;
    border: solid 1px $black-hover-color;
    user-select: none;
    font-weight: 500;
    border-right: none;
    transition: 200ms ease-in-out;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .toggleTitleDiv span:nth-child(1){
    border-radius: 6px 0 0 6px;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .toggleTitleDiv span:nth-last-child(1){
    border: solid 1px $black-hover-color;
    border-radius: 0 6px 6px 0;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer .toggleTitleDiv span.selected{
    box-shadow: 0px 2px 20px $black-hover-color;
    background-color: $blue-color;
    color: $white-color;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dropPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.25rem;
    height: 2rem;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dropPlaceholder span {
    padding: 0.2rem 1rem;
    color: $blue-color;
    background: $white-color;
    border-radius: 6px;
    font-size: .9rem;
    transition: 300ms ease-in-out;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .dragableContainer.disable .dropPlaceholder span {
    color: $secondary-color;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .axisInnerDivAdd {
    display: flex;
    justify-content: center;
    margin: .25rem;
}
.chartPopUp .mainContainer .secondColumn .outerDiv .axisInnerDivAdd button{
    background-color: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border: none;
    outline: none;
    font-size: 1.6rem;
    border-radius: 6px;
    color: $blue-color;
}

//ToolTip

.chartPopUp .mainContainer .scrollableDiv .toolTipTitleContainer .toolTipTitle{
    width: calc(100% - 1rem);
    overflow: hidden;
    text-overflow: ellipsis;
}
.chartPopUp .mainContainer .scrollableDiv .outerDiv .grayContainers .containerItems .toolTipTitleContainer .toolTipTitle span{
    display:contents;
}
.chartPopUp .mainContainer .scrollableDiv .toolTipInputDiv{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.chartPopUp .mainContainer .scrollableDiv .toolTipInputDiv input{
    width: 100%;
    padding:  .5rem .75rem;
    // color: $blue-light-1-color;
    background: transparent;
    background: $gray-light-3-color;
    outline: none;
    border: none;
    border-radius: 6px;
}
.chartPopUp .mainContainer .scrollableDiv .toolTipInputDiv .visibilityToggleDiv{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

//Filter

.chartPopUp .mainContainer .scrollableDiv .filterDiv .filterButton{
    padding: .5rem .5rem;
}
.chartPopUp .mainContainer .scrollableDiv .filterDiv .filterButton .primaryButton{
    width: 100%;
    position: relative;
}
.chartPopUp .mainContainer .scrollableDiv .filterDiv .filterButton .primaryButton .filterButtonNumber{
    background: $blue-color;
    border: solid 1px $blue-light-2-color;
    color: $white-color;
    position: absolute;
    right: -0.5rem;
    font-size: 0.7rem;
    top: -0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
}
