$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$gray-light-4-color: #EEF0F8;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;


.ReportSettings .saveReportContainer {
  display: flex;
  justify-content: center;

}
.ReportSettings .saveReportContainer .innerSaveReportContainer {
  display: grid;
  gap: 1rem;
  padding: 1rem;
  width: clamp(10rem, 100%, 40rem);
}

.ReportSettings .saveReportContainer .innerSaveReportContainer .saveReportTitleDiv{
  font-weight: 500;
}
.ReportSettings .saveReportContainer .innerSaveReportContainer .saveReportDiscriptionDiv{
  font-size: .8rem;
  font-weight: 500;
  color: $secondary-color;
}



.ReportSettings .saveReportContainer .innerSaveReportContainer .reportTimeFrame {
  // display: flex;
  // gap: 1rem;
  // flex-direction: column;
  // grid-template-columns: auto 1fr;
  // align-items: center;
}
.ReportSettings .saveReportContainer .innerSaveReportContainer .reportTimeFrame.calendarWrap {
  align-items: center;
}
.ReportSettings .saveReportContainer .innerSaveReportContainer .reportTimeFrame .reportTimeFrameOptionsContainer{
  padding: 0.5rem 0.75rem;
  display: grid;
  gap: .25rem;
  // background: $gray-light-3-color;
  outline: none;
  border: none;
  border-radius: 6px;
  resize: none;
  width: 14rem;
}

.ReportSettings .saveReportContainer .innerSaveReportContainer .reportTimeFrame .reportTimeFrameOptionsContainer .reportTimeFrameOption{
  display: grid;
  gap: 1rem;
  align-items: center;
  background-color: $white-color;
  grid-template-columns: auto 1fr;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: 100ms ease-in-out;
  border-radius: 3px;
}
.ReportSettings .saveReportContainer .innerSaveReportContainer .reportTimeFrame .reportTimeFrameOptionsContainer .reportTimeFrameOption:hover{
  box-shadow:  0 0 1px 1px $black-hover-color;
}

.ReportSettings .saveReportContainer .innerSaveReportContainer .reportSendNowButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.ReportSettings .saveReportContainer .innerSaveReportContainer .reportSendNowChildernContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ReportSettings .saveReportContainer .innerSaveReportContainer .reportTimeFrame .reportTimeFrameOptionsContainer .reportTimeFrameOption .checkBoxText{
  font-weight: 500;
  color: $secondary-color;
}

.ReportSettings .saveReportContainer .innerSaveReportContainer .reportSendNowPopUpInnerContaier{
  min-height: 30%;
}
.ReportSettings .saveReportContainer .innerSaveReportContainer .reportSendNowChildernContainer{
  padding: 0rem 1rem;
}
.ReportSettings .saveReportContainer .innerSaveReportContainer .reportSendNowChildernContainer .saveReportDiscriptionDiv{
  text-align: center;
}