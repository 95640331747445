$white-color:white;
$Light-Blue-1-Color:#c2daff;
$Light-Blue-2-Color:#aec8f0;


$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color:#155724;
$green-2-color:#c3e6cb;
$green-3-color:#d4edda;



// .CheckBoxInputContainer{
//     display: grid;
//     gap: .5rem;
//     align-items: center;
//     grid-template-columns: auto 1fr;
// }

.CustomCheckBoxInputContainer {
    content: "";
    height: 1rem;
    width: 1rem;
    box-shadow: 0 0 0 1px $black-hover-color;
    border-radius: 2px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 0.1rem;
    background-color: $white-color;
    color: $white-color;
    transition: 100ms ease-in-out;
    cursor: pointer;
    &.mediumCheckBox{
        height: 1.25rem;
        width: 1.25rem; 
    }
    &.largeCheckBox{
        height: 1.5rem;
        width: 1.5rem; 
    }
}

.CustomCheckBoxInputContainer:hover{
    background-color: $gray-light-2-color;
}
.CustomCheckBoxInputContainer:active{
    background-color: $gray-light-1-color;
}
.CustomCheckBoxInputContainer.checked{
    background-color: $blue-color;
    font-size: 0.7rem;
}
.CustomCheckBoxInputContainer.disabled{
    background-color: $gray-light-3-color;
    font-size: 0.7rem;
    cursor: auto;
}
.CustomCheckBoxInputContainer .checkIcon{
    opacity: 0;
}
.CustomCheckBoxInputContainer.checked .checkIcon{
    opacity: 1;
}