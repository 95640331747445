.btn-hover-danger {
  color: #3f4254!important;
  border-color: #e4e6ef!important;
  background-color: #e4e6ef!important;
}

.btn-hover-danger:hover {
  color: #ffffff!important;
  border-color: #f1416c!important;
  background-color: #f1416c!important;
}

.text-hover-danger {
  color: #3f4254!important;
}
.text-hover-danger:hover {
  color: #f1416c!important;
}

.loader-container {
  display: inline-flex;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #009ef7; /* Blue border */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  animation: spin 1s linear infinite; /* Rotate animation */
}

.loader-red {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #f1416c; /* Blue border */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  animation: spin 1s linear infinite; /* Rotate animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-supista {
  min-height: 188px;
}

/* bootstrap classes */
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 0.75rem !important;
}
.ml-4 {
  margin-left: 1rem !important;
}
.ml-5 {
  margin-left: 1.25rem !important;
}
.ml-6 {
  margin-left: 1.5rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 0.75rem !important;
}
.mr-4 {
  margin-right: 1rem !important;
}
.mr-5 {
  margin-right: 1.25rem !important;
}
.mr-6 {
  margin-right: 1.5rem !important;
}